import {
  DataSourceMethodsCreateOptions,
  DataSourceMethodsEditOptions,
  DefaultCRUDDataSource,
  DefaultCRUDDataSourceImpl,
  DefaultREADDataSourceWithPagination,
  DefaultREADDataSourceWithPaginationImpl,
} from '@tremaze/shared/util-http';
import { ContactPoint } from '@tremaze/shared/feature/contact-point/types';
import { HttpClient } from '@angular/common/http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export abstract class ContactPointReadDataSourceWithPagination extends DefaultREADDataSourceWithPagination<ContactPoint> {}

@Injectable({ providedIn: 'root' })
export abstract class ContactPointCRUDDataSource extends DefaultCRUDDataSource<ContactPoint> {}

@Injectable({ providedIn: 'root' })
export class RemoteContactPointReadDataSourceDefaultImpl
  extends DefaultREADDataSourceWithPaginationImpl<ContactPoint>
  implements ContactPointReadDataSourceWithPagination
{
  deserializer = ContactPoint.deserialize;
  protected controller = 'public/contactPoints';

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
  ) {
    super();
  }
}

type CreateDTO = Omit<ContactPoint, 'id' | 'meta' | 'logo'> & {
  logoId?: string;
};

function contactPointToCreateDTO(i: ContactPoint): CreateDTO {
  return {
    address: i.address,
    contact: i.contact,
    contactPersons: i.contactPersons,
    text: i.text,
    name: i.name,
    logoId: i.logo?.id,
    url: i.url,
    faxNumber: i.faxNumber,
  };
}

function contactPointToEditDTO(i: ContactPoint): EditDTO {
  return {
    ...contactPointToCreateDTO(i),
    id: i.id,
  };
}

interface EditDTO extends CreateDTO {
  id: string;
}

@Injectable({ providedIn: 'root' })
export class RemoteContactPointCRUDDataSourceDefaultImpl
  extends DefaultCRUDDataSourceImpl<ContactPoint>
  implements ContactPointCRUDDataSource
{
  deserializer = ContactPoint.deserialize;
  protected controller = 'contactPoints';

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
  ) {
    super();
  }

  create(
    i: ContactPoint,
    options?: DataSourceMethodsCreateOptions<ContactPoint>,
  ): Observable<ContactPoint> {
    return super.create(contactPointToCreateDTO(i) as never, options);
  }

  edit(
    i: ContactPoint,
    options?: DataSourceMethodsEditOptions<ContactPoint>,
  ): Observable<ContactPoint> {
    return super.edit(contactPointToEditDTO(i) as never, options);
  }
}
