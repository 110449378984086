import {
  booleanAttribute,
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FolderViewComponentService } from '../folder-view-component.service';
import { CommonModule } from '@angular/common';
import { FolderViewFoldersAndFilesComponent } from './folders-and-files/folder-view-folders-and-files.component';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { FolderViewActionsRowComponent } from './actions-row/folder-view-actions-row.component';
import componentServices from '../component-services';
import { PdfViewerModule } from '@tremaze/shared/feature/pdf-viewer';
import { SharedNotificationModule } from '@tremaze/shared/notification';
import { MatButton } from '@angular/material/button';
import { firstValueFrom } from 'rxjs';
import { clearAllTextSelection } from '@tremaze/shared/util-utilities';
import { FolderViewFileOpenerService } from '../services/folder-view-file-opener.service';
import { FolderViewSelectionType } from '../types';
import { Placeholder } from '@tremaze/placeholder';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'tremaze-folder-view',
  standalone: true,
  imports: [
    CommonModule,
    FolderViewFoldersAndFilesComponent,
    FolderViewActionsRowComponent,
    PdfViewerModule,
    SharedNotificationModule,
    MatButton,
  ],
  templateUrl: './folder-view.component.html',
  styleUrls: ['./folder-view.component.scss'],
  providers: componentServices,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'folder-view',
  },
})
export class FolderViewComponent implements OnDestroy {
  readonly service = inject(FolderViewComponentService);
  private readonly _fileOpenerService = inject(FolderViewFileOpenerService);

  private _isCreatingFolder = false;

  get isCreatingFolder() {
    return this._isCreatingFolder;
  }

  @Input() set selectionRegex(value: RegExp | undefined) {
    this.service.setSelectionRegex(value);
  }

  private _selectionType?: FolderViewSelectionType;

  get selectionType() {
    return this._selectionType;
  }

  @Input() set selectionType(value: FolderViewSelectionType | undefined) {
    this._selectionType = value;
    this.service.setSelectionType(value);
  }

  get selectionEnabled() {
    return !!this._selectionType;
  }

  get singleSelection() {
    return (
      this._selectionType === 'singleFile' ||
      this._selectionType === 'singleFolder'
    );
  }

  get canSelectFiles() {
    return (
      this._selectionType === 'singleFile' ||
      this._selectionType === 'multipleFiles' ||
      this._selectionType === 'mixed'
    );
  }

  get canSelectFolders() {
    return (
      this._selectionType === 'singleFolder' ||
      this._selectionType === 'multipleFolders' ||
      this._selectionType === 'mixed'
    );
  }

  @Input() set placeholders(value: Placeholder[] | null | undefined) {
    this._fileOpenerService.placeholders = value ?? [];
  }

  @Input({ transform: booleanAttribute }) showSelectFromFileStorageButton =
    false;

  @Input() set openFileCallback(
    value: undefined | ((file: FileStorage) => boolean | null),
  ) {
    this.service.setFileOpenerCallback(value);
  }

  @Output() readonly clickedSelectFromFileStorage = new EventEmitter<void>();

  @Output() readonly confirmSelection = new EventEmitter<{
    files: FileStorage[];
    dirs: DirStorage[];
  }>();

  ngOnDestroy() {
    this.service.clearSelection();
  }

  onNavigate(path: string) {
    this.service.navigateToPath(path);
  }

  public copyFileToCurrentFolder(file: FileStorage) {
    this.service.copyFileToCurrentFolder(file);
  }

  protected onDoubleClickDirectory(dir: DirStorage) {
    this.service.openDirectory(dir);
  }

  protected onClickDirectory(dir: DirStorage) {
    this.service.switchDirectorySelection(dir);
  }

  protected onClickFile(file: FileStorage) {
    this.service.switchFileSelection(file);
  }

  protected onDoubleClickFile(file: FileStorage) {
    if (this.singleSelection && this.canSelectFiles) {
      this.confirmSelection.emit({ files: [file], dirs: [] });
      return;
    }
    this.service.openFile(file);
  }

  createFolder() {
    this._isCreatingFolder = true;
  }

  cancelCreateFolder() {
    this._isCreatingFolder = false;
  }

  submitFolderName({ name, folderId }: { name: string; folderId?: string }) {
    if (!folderId) {
      this.service.createDirectory(name);
      this._isCreatingFolder = false;
    } else {
      this.service.updateDirectoryName(folderId, name);
    }
  }

  submitFileName({ name, fileId }: { name: string; fileId: string }) {
    this.service.updateFileName(fileId, name);
  }

  onFilesSelected(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      this.service.uploadFiles(Array.from(files));
    }
  }

  onClickSelectFromFileStorage() {
    this.clickedSelectFromFileStorage.emit();
  }

  async onClickConfirmSelection() {
    let selectedFiles: FileStorage[] = [];
    let selectedDirs: DirStorage[] = [];
    if (this.canSelectFiles) {
      selectedFiles = await firstValueFrom(this.service.selectedFiles$);
    }
    if (this.canSelectFolders) {
      selectedDirs = await firstValueFrom(this.service.selectedDirectories$);
    }
    if (selectedFiles?.length || selectedDirs?.length) {
      this.confirmSelection.emit({ files: selectedFiles, dirs: selectedDirs });
    }
  }

  async onClickSelectCurrentFolder() {
    const currentFolder = await firstValueFrom(this.service.currentDirectory$);

    this.confirmSelection.emit({
      files: [],
      dirs: [currentFolder],
    });
  }

  onMoveFilesAndFolders({
    files,
    folders,
    targetPath,
  }: {
    files: FileStorage[];
    folders: DirStorage[];
    targetPath: string;
  }) {
    this.service.moveFilesAndFolders(files, folders, targetPath);
  }

  onDownloadFilesAndFolders({
    files,
    folders,
  }: {
    files: FileStorage[];
    folders: DirStorage[];
  }) {
    this.service.downloadFilesAndFolders(files, folders);
  }

  @HostListener('keydown', ['$event'])
  private _pressedCtl(event: Event) {
    if ((event as KeyboardEvent).ctrlKey) {
      clearAllTextSelection();
      document.body.style.userSelect = 'none';
    }
  }

  @HostListener('keyup.escape')
  private resetSelection() {
    this.service.clearSelection();
  }

  @HostListener('keyup', ['$event'])
  private selectAll(event: KeyboardEvent) {
    if (event.ctrlKey) {
      if (event.key === 'a') {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();
        this.service.selectAll();
      }
    }

    if (event.key === 'Control') {
      document.body.style.userSelect = '';
      clearAllTextSelection();
    }
  }

  onSearchValueChange(value: string) {
    this.service.setSearchValue(value);
  }

  onFindClientFilesSettingChange(value: boolean) {
    this.service.setFindClientFilesSetting(value);
  }
}
