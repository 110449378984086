@if (isLoading) {
  <mat-progress-bar mode="indeterminate" color="accent"
                    style="position: absolute; top: 0; left: 0; right: 0; z-index: 10000"></mat-progress-bar>
}
@if (folder) {
  <div
    class="folder-view-folders-and-files__Head"
  >
    <tremaze-folder-view-breadcrumbs
      [path]="folder.absolutePath"
      [viewPath]="folder.absoluteViewPath"
      (navigate)="onClickBreadcrumb($event)"
      (dataDropped)="onDataDropped($event.data, $event.path)"
      (filesDropped)="onFilesDropped($event.files, $event.path)"
    ></tremaze-folder-view-breadcrumbs>

    <span class="spacer"></span>

    <tremaze-expanding-icon-text-input
      [value]="searchValue"
      (valueChange)="searchValueChange.emit($event)"
    ></tremaze-expanding-icon-text-input>

    <mat-button-toggle-group [value]="viewMode">
      <mat-button-toggle value="grid" (click)="viewMode = 'grid'">
        <tremaze-icon icon="lnr-grid"></tremaze-icon>
      </mat-button-toggle>
      <mat-button-toggle value="list" (click)="viewMode = 'list'">
        <tremaze-icon icon="lnr-list"></tremaze-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>

    <button mat-icon-button [matMenuTriggerFor]="menu" *tremazeHasPermission="clientFileStorageSearchPermissionRequest">
      <tremaze-icon icon="lnr-ellipsis"></tremaze-icon>
    </button>
  </div>
  <div class="folder-view-folders-and-files__Body"
       tremazeFileDropInDropZone
       #currentDirDropZone="fileDropInDropZone"
       (filesDropped)="onFilesDropped($event)"
  >

    @if (viewMode === 'list') {


      <tremaze-folder-view-list-layout-list
        [folders]="folders ?? []"
        [files]="files ?? []"
        [selectedFiles]="selectedFiles ?? []"
        [selectedFolders]="selectedFolders ?? []"
        [getCanDeleteStreamForFileOrFolder]="getCanDeleteStreamForFileOrFolder"
        [getCanWriteStreamForFileOrFolder]="getCanWriteStreamForFileOrFolder"
        [selectionType]='selectionType ?? "mixed"'
        [showCreateFolderRow]="showCreateFolder"
        (showFilePreview)="showFilePreview.emit($event)"
        (selectionChange)="selectionChange.emit($event)"
        (deleteFolder)="deleteFolder.emit($event)"
        (deleteFile)="deleteFile.emit($event)"
        (downloadFile)="downloadFile.emit($event)"
        (copyFileLink)="copyFileLink.emit($event)"
        (navigateToPath)="navigate.emit($event)"
        (duplicateFile)="duplicateFile.emit($event)"
        (openFolder)="openFolder.emit($event)"
        (clickedFile)="clickedFile.emit($event)"
        (clickedFolder)="clickedFolder.emit($event)"
        (doubleClickedFile)="doubleClickedFile.emit($event)"
        (doubleClickedFolder)="doubleClickedFolder.emit($event)"
        (submitFileName)="submitFileName.emit({
                                              fileId: $event.file.id,
                                              name: $event.name,
                                            })"
        (submitFolderName)="onSubmitFolderName($event.name, $event.folder?.id)"
        (cancelCreateFolder)="onCancelCreateFolder()"
        (moveFilesAndFoldersToFolder)="onDataDropped($event.data, $event.target.absolutePath)"
        (uploadFiles)="uploadFiles.emit({files: $event.files, path: $event.target.absolutePath})"
        style="padding-bottom: 80px"
      ></tremaze-folder-view-list-layout-list>

      <!--      @if (currentDirDropZone.isDraggingFiles) {-->
      <div class="folder-view-folders-and-files__Files-Wrapper__Drop-Zone">
      </div>
      <!--      }-->

    } @else {
      <div style="padding: 20px">
        <h4>Ordner</h4>
        @if (folders?.length || showCreateFolder) {
          <tremaze-folder-view-grid-layout-folders @list>
            @for (folder of folders; track folder.id) {
              <button
                tremaze-folder-view-grid-layout-folder-item
                tremazeSelectZoneItem
                tremazeDragZoneItem
                tremazeDropZone
                tremazeFileDropInDropZone
                [dragData]="folder"
                [dragDisabled]="!isFolderSelected(folder)"
                [selectionData]="folder"
                [selected]="isFolderSelected(folder)"
                [folder]="folder"
                [canWrite$]="getCanWriteStreamForFileOrFolder?.(folder)"
                [dropZoneDisabled]="
              (getCanWriteStreamForFileOrFolder?.(folder) | async) === false
            "
                [selectionDisabled]="!folder.canBeTinkeredWith"
                (filesDropped)="onFilesDropped($event, folder.absolutePath)"
                (dropped)="onDataDropped($event, folder.absolutePath)"
                (click)="onClickFolder(folder, $event)"
                (dblclick)="onDoubleClickFolder(folder)"
                (openFolder)="openFolder.emit(folder)"
                (deleteFolder)="deleteFolder.emit(folder)"
                (submitFolderName)="onSubmitFolderName($event, folder.id)"
              ></button>
            }

            @if (showCreateFolder) {
              <tremaze-folder-view-grid-layout-folder-item
                isCreateFolderItem
                (submitFolderName)="onSubmitFolderName($event)"
                (cancelCreateFolder)="onCancelCreateFolder()"
              ></tremaze-folder-view-grid-layout-folder-item>
            }
          </tremaze-folder-view-grid-layout-folders>
        }
        <h4>Dateien</h4>
        <div
          class="folder-view-folders-and-files__Files-Wrapper"
        >
          @if (files?.length) {
            <tremaze-folder-view-grid-layout-files @list style="padding-bottom: 80px">
              @for (file of files; track file.id) {
                <figure
                  tremaze-folder-view-grid-layout-file-item
                  tremazeSelectZoneItem
                  tremazeDragZoneItem
                  tabindex="0"
                  [dragData]="file"
                  [dragDisabled]="!isFileSelected(file)"
                  [selectionData]="file"
                  [file]="file"
                  [selected]="isFileSelected(file)"
                  [disabled]="isFileDisabled(file)"
                  [canWrite$]="getCanWriteStreamForFileOrFolder?.(file)"
                  [canDelete$]="getCanDeleteStreamForFileOrFolder?.(file)"
                  (click)="onClickFile(file, $event)"
                  (dblclick)="onDoubleClickFile(file)"
                  (showFilePreview)="showFilePreview.emit(file)"
                  (duplicateFile)="duplicateFile.emit(file)"
                  (deleteFile)="deleteFile.emit(file)"
                  (copyFileLink)="copyFileLink.emit(file)"
                  (downloadFile)="downloadFile.emit(file)"
                  (navigateToPath)="navigate.emit(file.absolutePath)"
                  (submitFileName)="
                submitFileName.emit({
                  fileId: file.id,
                  name: $event,
                })
              "
                ></figure>
              }
            </tremaze-folder-view-grid-layout-files>
          } @else if (isLoadingFiles) {
            <div class="center-children" style="padding: 50px">
              <mat-spinner [diameter]="30"></mat-spinner>
            </div>
          } @else {
            <div class="center-children" style="padding-top: 100px">
              <p>Keine Dateien vorhanden</p>
            </div>
          }
          @if (currentDirDropZone.isDraggingFiles) {
            <div class="folder-view-folders-and-files__Files-Wrapper__Drop-Zone">
              <div>
                <p>Dateien hier hin ziehen</p>
                <tremaze-icon icon="lnr-download2"></tremaze-icon>
              </div>
            </div>
          }
        </div>
      </div>

    }

  </div>
}

<tremaze-drag-zone-preview>
  <tremaze-folder-view-drag-preview
    [files]="selectedFiles"
    [folders]="selectedFolders"
  ></tremaze-folder-view-drag-preview>
</tremaze-drag-zone-preview>

<mat-menu #menu="matMenu">

  <div mat-menu-item (click)="$event.stopPropagation(); checkbox.toggle()" (keydown.enter)="$event.stopPropagation(); checkbox.toggle()">
    <mat-checkbox (change)="findClientFilesSettingChange.emit($event.checked)" (click)="$event.stopPropagation()" #checkbox>Dateien von Klient*innen finden</mat-checkbox>
  </div>

</mat-menu>
