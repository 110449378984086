<ng-container [formGroup]="formGroup">
  <section class="form-grid">
    <mat-form-field
      subscriptSizing="dynamic"
      *ngIf="eventTemplateForm.showInstitutionSelector$ | async"
    >
      <mat-label>Einrichtungen</mat-label>
      <tremaze-institution-select
        [forPrivilege]="['EVENT_WRITE']"
        formControlName="institutions"
        multiple
      ></tremaze-institution-select>
      <mat-hint
        >Dies legt fest, wie die Zuweisung der hieraus generierten Termine
        aussehen soll
      </mat-hint>
    </mat-form-field>

    <mat-form-field
      *ngIf="
        (eventTemplateForm.showDepartmentSelector$ | async) &&
        showDepartmentSelector
      "
    >
      <mat-label>Abteilungen</mat-label>
      <tremaze-chips-autocomplete
        [dataSource]="departmentAutocompleteDataSource"
        formControlName="departments"
      ></tremaze-chips-autocomplete>
    </mat-form-field>

    <ng-container>
      <mat-form-field>
        <mat-label>Verfügbar für...</mat-label>
        <tremaze-institution-select
          [forPrivilege]="[
            'EVENT_WRITE',
            'EVENT_WRITE_DEPARTMENT',
            'EVENT_WRITE_REFERENCE_CLIENT',
          ]"
          formControlName="institutionAssignments"
          [required]="eventTemplateForm.isInstitutionAssignmentsRequired"
          multiple
        ></tremaze-institution-select>
        <mat-hint
          >Hier kannst du festlegen, für welche Einrichtungen diese Vorlage
          verfügbar gemacht werden soll
        </mat-hint>
        <mat-error *ngIf="eventTemplateForm.isInstitutionAssignmentsInvalid"
          >{{ eventTemplateForm.institutionAssignmentsErrorMsg }}
        </mat-error>
      </mat-form-field>
    </ng-container>


    <mat-checkbox class="form-grid-item-full-width"
      formControlName="visibleForAllReferenceClients"
    >Diesen Termin nur für meine Bezugsklient*innen anzeigen
    </mat-checkbox>

    <mat-form-field
      *ngIf="canSelectUsers && (eventTemplateForm.showUserSelector$ | async)"
    >
      <mat-label>Mitarbeiter*Innen</mat-label>
      <tremaze-chips-autocomplete
        [dataSource]="employeesDataSource"
        [getOptionLabel]="userDisplayWith"
        [getOptionAvatar]="userAvatarFn"
        [getOptionInitials]="userInitialsFn"
        [getOptionSubtitle]="userSubtitleFn"
        formControlName="employees"
      ></tremaze-chips-autocomplete>
    </mat-form-field>

    <mat-form-field
      *ngIf="canSelectUsers && (eventTemplateForm.showUserSelector$ | async)"
    >
      <mat-label>Klient*Innen</mat-label>
      <tremaze-chips-autocomplete
        [dataSource]="clientsDataSource"
        [getOptionLabel]="userDisplayWith"
        [getOptionAvatar]="userAvatarFn"
        [getOptionInitials]="userInitialsFn"
        [getOptionSubtitle]="userSubtitleFn"
        formControlName="clients"
      ></tremaze-chips-autocomplete>
    </mat-form-field>

    <ng-container *ifModuleEnabled="'SPECIALIZATION'">
      <mat-form-field
        *ngIf="(disableSpecializationSettings$ | async) === false"
      >
        <mat-label>Förderbereiche</mat-label>
        <mat-select
          [multiple]="hasSpecializations$"
          formControlName="specializations"
          [compareWith]="idCompareFn"
        >
          @if (hasSpecializations$ | async) {
            <mat-option
              *ngFor="let spec of specializations$ | async"
              [value]="spec"
              >{{ spec.name }}
            </mat-option>
          } @else {
            <mat-option value="" disabled
              >Keine Förderbereiche gefunden</mat-option
            >
          }
        </mat-select>
      </mat-form-field>
    </ng-container>
  </section>
</ng-container>
