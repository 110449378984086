<ng-container [formGroup]="formGroup">
  <section class="form-grid">
    <div class="form-grid-item-3-col">
      <mat-checkbox
        formControlName="enableParticipationInfo"
        tremazeFeatureAssistant
        featureName="Auswahl des Termingrunds"
        [featureAssistantMessage]="featureAssistantMessage"
        featureAssistantXOffset="19px"
        featureAssistantYOffset="12px"
        maxDialogWidth="500px"
      >
        Nutzer*innen dürfen bei der Buchung den Termingrund wählen
      </mat-checkbox>
    </div>

    <tremaze-hint class="form-grid-item-full-width">
      Wähle die Terminarten aus, die Nutzer*innen bei der Anmeldung zu diesem
      Termin auswählen können sollen. Wenn du keine auswählst, können bei der
      Anmeldung auch keine gewählt werden.
    </tremaze-hint>

    <tremaze-event-participation-type-selector
      multiple
      formControlName="participationTypes"
      class="form-grid-item-new-row form-grid-item-2-col"
    ></tremaze-event-participation-type-selector>

    <div class="form-grid-item-new-row">
      <mat-checkbox formControlName="canBeReassigned"
                    tremazeFeatureAssistant
                    featureAssistantXOffset="19px"
                    featureAssistantYOffset="12px"
                    maxDialogWidth="500px"
                    featureName="Termin nachvergeben"
                    featureAssistantMessage="Mit dieser Funktion kann ein geplanter Termin im Fall der Absage durch beteiligte Klient*innen anhand der gewählten Vorlage automatisch wieder zur Buchung durch andere Personen bereitgestellt werden.">
        Termin automatisch nachvergeben
      </mat-checkbox>
    </div>

    @if (showTemplateAutocomplete) {
      <mat-form-field>
        <mat-label>Vorlage zur Nachvergabe</mat-label>
        <tremaze-autocomplete [dataSource]="eventTemplateAutocompleteDataSource"
                              formControlName="canBeReassignedTemplate"
                              [getOptionLabel]="displayTemplateFn"></tremaze-autocomplete>
      </mat-form-field>
    }
  </section>
</ng-container>
