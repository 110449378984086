import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventTemplateEditService } from './edit.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { EventTemplateEditDialogComponent } from './edit-dialog.component';
import { EventTemplateEditComponent } from './edit.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedFeatureFileStorageUiFileSelectorInputModule } from '@tremaze/shared/feature/file-storage/ui/file-selector/input';
import { SharedFeatureInstitutionFeatureSelectModule } from '@tremaze/shared/feature/institution/feature/select';
import { SharedFeatureAddressFeatureAddressEditModule } from '@tremaze/shared/feature/address/feature/address-edit';
import { SharedUiSelectorModule } from '@tremaze/shared/ui/selector';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SharedFeatureParticipationCriteriaFeatureParticipationCriteriaSelectionModule } from '@tremaze/shared/feature/participation-criteria/feature/participation-criteria-selection';
import { SharedUiRichTextEditorModule } from '@tremaze/shared/ui/rich-text-editor';
import { SharedFeatureEventFeaturePriceLevelSettingModule } from '@tremaze/shared/feature/event/feature/price-level-setting';
import { MatSelectModule } from '@angular/material/select';
import { SharedFeatureCategoryFeatureSelectModule } from '@tremaze/shared/feature/category/feature/select';
import { SharedComponentsChipsAutocompleteModule } from '@tremaze/shared-components-chips-autocomplete';
import { IfModuleEnabledDirective } from '@tremaze/shared/tenant-config';
import { SharedUiAutocompleteModule } from '@tremaze/shared/ui/autocomplete';
import { SharedFeatureCustomFormsFeatureSelectionModule } from '@tremaze/shared/feature/custom-forms/feature/selection';
import { EventTemplateFormGeneralSectionComponent } from './template-form-sections/general/general.component';
import { EventTemplateFormAllocationSectionComponent } from './template-form-sections/allocation/allocation.component';
import { EventTemplateFormAddressSectionComponent } from './template-form-sections/address/address.component';
import { EventTemplateFormSettingsSectionComponent } from './template-form-sections/settings/settings.component';
import { EventTemplateFormDescriptionSectionComponent } from './template-form-sections/description/description.component';
import { MatTooltip } from '@angular/material/tooltip';
import { SharedUiFeatureAssistantModule } from '@tremaze/shared/ui/feature-assistant';
import { EventTemplateScheduleSettingsComponent } from './template-form-sections/schedule-settings/schedule-settings.component';
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerToggle,
} from '@angular/material/datepicker';
import { SharedUiHintModule } from '@tremaze/shared/ui/hint';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { EventTemplateRegistrationSettingsComponent } from './template-form-sections/registration/event-template-registration-settings.component';
import { SharedUiPanelListModule } from '@tremaze/shared/ui/panel-list';
import { EventTemplateEditTimeSettingsComponent } from './template-form-sections/time/event-template-edit-time-settings.component';
import { EventParticipationTypeSelectorComponent } from '@tremaze/event-participation-type-selector';
import { EventTemplateFormApprovalSectionComponent } from './template-form-sections/approval/event-template-form-approval-section.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    SharedFeatureFileStorageUiFileSelectorInputModule,
    SharedFeatureInstitutionFeatureSelectModule,
    SharedFeatureAddressFeatureAddressEditModule,
    SharedUiSelectorModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCheckboxModule,
    SharedFeatureParticipationCriteriaFeatureParticipationCriteriaSelectionModule,
    SharedUiRichTextEditorModule,
    SharedFeatureEventFeaturePriceLevelSettingModule,
    MatSelectModule,
    SharedFeatureCategoryFeatureSelectModule,
    SharedComponentsChipsAutocompleteModule,
    IfModuleEnabledDirective,
    SharedUiAutocompleteModule,
    SharedFeatureCustomFormsFeatureSelectionModule,
    MatTooltip,
    SharedUiFeatureAssistantModule,
    MatDatepickerToggle,
    MatDatepickerInput,
    MatDatepicker,
    SharedUiHintModule,
    MatSelectionList,
    MatListOption,
    SharedUiPanelListModule,
    EventParticipationTypeSelectorComponent,
  ],
  declarations: [
    EventTemplateEditComponent,
    EventTemplateEditDialogComponent,
    EventTemplateFormGeneralSectionComponent,
    EventTemplateFormAllocationSectionComponent,
    EventTemplateFormAddressSectionComponent,
    EventTemplateFormSettingsSectionComponent,
    EventTemplateFormDescriptionSectionComponent,
    EventTemplateScheduleSettingsComponent,
    EventTemplateRegistrationSettingsComponent,
    EventTemplateEditTimeSettingsComponent,
    EventTemplateFormApprovalSectionComponent,
  ],
  providers: [EventTemplateEditService],
  exports: [EventTemplateEditComponent],
})
export class SharedFeatureEventTemplateFeatureEditModule {}
