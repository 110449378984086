import {
  EventParticipationType,
  EventScope,
  EventStatus,
  TremazeEvent,
} from '@tremaze/shared/feature/event/types';
import {
  DataSourceMethodsDeleteOptions,
  DataSourceMethodsPaginatedOptions,
  DefaultREADDataSource,
  DefaultREADDataSourceWithPagination,
} from '@tremaze/shared/util-http';
import { DateRange, TremazeDate } from '@tremaze/shared/util-date';
import { Observable } from 'rxjs';

export type EventTypes = 'EVENT' | 'HIGHLIGHT';

export type EventDateRange = DateRange | [TremazeDate];

export abstract class EventREADDataSource extends DefaultREADDataSource<TremazeEvent> {
  abstract getEventsInDateRange(
    dateRange: EventDateRange,
    types?: EventTypes[],
    onlyHolidayEvents?: boolean,
    options?: DataSourceMethodsPaginatedOptions,
    instIds?: string[],
    departmentIds?: string[],
    userIds?: string[],
    maxResults?: number,
    status?: EventStatus[],
    path?: string,
  ): Observable<TremazeEvent[]>;

  abstract getEventsInDateRangeForUser(
    dateRange: EventDateRange,
    userId: string,
    types?: EventTypes[],
    onlyHolidayEvents?: boolean,
    options?: DataSourceMethodsPaginatedOptions,
    userIds?: string[],
    maxResults?: number,
    status?: EventStatus[],
  ): Observable<TremazeEvent[]>;

  abstract getAllParticipationTypes(): Observable<EventParticipationType[]>;
}

export interface EventScopeOptions {
  scope?: EventScope;
}

export type EventPublishScopeOptions = EventScopeOptions & {
  scope: Omit<EventScope, 'FROM_DATE'>;
};

export abstract class EventDataSource
  extends DefaultREADDataSourceWithPagination<TremazeEvent>
  implements EventREADDataSource
{
  abstract deleteById(
    id: string,
    options?: DataSourceMethodsDeleteOptions & EventScopeOptions,
    notifyUsers?: boolean,
  ): Observable<boolean>;

  abstract getEventsInDateRange(
    dateRange: EventDateRange,
    types?: EventTypes[],
    onlyHolidayEvents?: boolean,
    options?: DataSourceMethodsPaginatedOptions,
    instIds?: string[],
    departmentIds?: string[],
    userIds?: string[],
    maxResults?: number,
    status?: EventStatus[],
  ): Observable<TremazeEvent[]>;

  abstract getEventsInDateRangeForUser(
    dateRange: EventDateRange,
    userId: string,
    types?: EventTypes[],
    onlyHolidayEvents?: boolean,
    options?: DataSourceMethodsPaginatedOptions,
    userIds?: string[],
    maxResults?: number,
    status?: EventStatus[],
  ): Observable<TremazeEvent[]>;

  abstract switchEventPublished(
    event: Pick<TremazeEvent, 'id' | 'published'>,
    options?: EventPublishScopeOptions,
    notifyUsers?: boolean,
  ): Observable<boolean>;

  abstract updateEventStatus(id: string, status: EventStatus): Observable<void>;

  abstract getAllParticipationTypes(): Observable<EventParticipationType[]>;

  abstract getCancellationReasonForEvent(
    eventId: string,
  ): Observable<string | null | undefined>;

  abstract updateCancellationReasonForEvent(
    eventId: string,
    reason?: string,
  ): Observable<void>;

  abstract moveEvent(
    eventId: string,
    startDate: TremazeDate,
    endDate: TremazeDate,
    allDay: boolean,
    moveUser?: {
      previousUserId: string;
      newUserId: string;
    },
  ): Observable<void>;
}
