import { Case } from '@tremaze/case-types';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import {
  ApprovalFixtures,
  DepartmentFixtures,
  InstitutionFixtures,
  UserFixtures,
} from '../';
import { CaseStatusFixtures } from './case-status';
import { CaseStatisticIdentifierFixtures } from './case-statistic-identifier';
import { TremazeDate } from '@tremaze/shared/util-date';
import { PageGenerator } from '../helpers';
import { longHtml } from '../../texts';

class CaseFixturePageGenerator extends PageGenerator<Case> {
  override generateByIndex(index: number): Case {
    return CaseFixtures.generateCase(index);
  }
}

export abstract class CaseFixtures {
  static generateCase = (
    id: number,
    overrides?: {
      additionalInfo?: string;
    },
  ): Case => ({
    id: `${id}`,
    status: CaseStatusFixtures.generateCaseStatus(id),
    client: UserFixtures.generateUser(id),
    employees: [UserFixtures.generateUser(id + 1)],
    institution: InstitutionFixtures.generateInstitution(id),
    caseNumber: `25-${(id + 1).toString().padStart(4, '0')}`,
    startDate: new TremazeDate('2021-01-01'),
    endDate: new TremazeDate('2021-12-31'),
    statisticsIdentifier:
      CaseStatisticIdentifierFixtures.generateCaseStatisticIdentifier(id),
    approvals: [ApprovalFixtures.generateApproval(id)],
    additionalInfo: overrides?.additionalInfo ?? 'Lorem ipsum dolor sit amet',
    departments: [DepartmentFixtures.department1],
    departmentInstIds: [InstitutionFixtures.institution1.id],
  });

  private static _caseStubPageGenerator = new CaseFixturePageGenerator();

  static generateCasePage = (options: DataSourceMethodsPaginatedOptions) =>
    CaseFixtures._caseStubPageGenerator.generatePage(options);

  static readonly case1: Case = CaseFixtures.generateCase(1);
  static readonly case2: Case = CaseFixtures.generateCase(2);
  static readonly case3: Case = CaseFixtures.generateCase(3);
  static readonly case4: Case = CaseFixtures.generateCase(4);
  static readonly case5: Case = CaseFixtures.generateCase(5);

  static readonly caseWithLongAdditionInfo = CaseFixtures.generateCase(6, {
    additionalInfo: longHtml,
  });

  static readonly cases = [
    CaseFixtures.case1,
    CaseFixtures.case2,
    CaseFixtures.case3,
    CaseFixtures.case4,
    CaseFixtures.case5,
  ];
}
