import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';
import { map } from 'rxjs/operators';
import { CopyToClipboardService } from '@tremaze/clipboard';
import { mergePaths } from '@tremaze/shared/util/string';
import { mapNotEmpty } from '@tremaze/shared/util/rxjs';

@Injectable({ providedIn: 'root' })
export class TenantWebsiteLinksService {
  private readonly _authService = inject(AuthV2Service);
  private readonly _copyToClipboardService = inject(CopyToClipboardService);

  private get _baseUrl$(): Observable<string> {
    return this._authService.activeTenant$.pipe(
      map((tenant) => tenant.websiteLink),
    );
  }

  readonly tenantWebsiteLinksEnabled$ = this._baseUrl$.pipe(mapNotEmpty());

  private _buildLink$(path: string): Observable<string> {
    return this._baseUrl$.pipe(map((baseUrl) => mergePaths(baseUrl, path)));
  }

  buildEventDetailsLink$(eventId: string): Observable<string> {
    return this._buildLink$(`dashboard/termine?termin=${eventId}`);
  }

  copyEventDetailsLinkToClipboard(eventId: string): Observable<string> {
    return this.buildEventDetailsLink$(eventId).pipe(
      this._copyToClipboardService.copyToClipboard$,
    );
  }

  buildInformationDetailsLink$(informationId: string): Observable<string> {
    return this._buildLink$(`dashboard/information/${informationId}`);
  }

  copyInformationDetailsLinkToClipboard(
    informationId: string,
  ): Observable<string> {
    return this.buildInformationDetailsLink$(informationId).pipe(
      this._copyToClipboardService.copyToClipboard$,
    );
  }

  buildCustomFormLink$(customFormId: string): Observable<string> {
    return this._buildLink$(`dashboard/formulare?formular=${customFormId}`);
  }

  copyCustomFormLinkToClipboard(customFormId: string): Observable<string> {
    return this.buildCustomFormLink$(customFormId).pipe(
      this._copyToClipboardService.copyToClipboard$,
    );
  }

  buildUserDetailsLink$(userId: string): Observable<string> {
    return this._buildLink$(`dashboard/nutzer/${userId}`);
  }

  copyUserDetailsLinkToClipboard(userId: string): Observable<string> {
    return this.buildUserDetailsLink$(userId).pipe(
      this._copyToClipboardService.copyToClipboard$,
    );
  }
}
