import { Injectable } from '@angular/core';
import { AssignableEditService } from '@tremaze/shared-feature-assignment-assignable-edit-service';
import { FormBuilder, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { Institution } from '@tremaze/shared/feature/institution/types';
import {
  EventTemplate,
  TremazeEvent,
} from '@tremaze/shared/feature/event/types';
import { firstValueFrom, from, of, Subject, takeUntil } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  pluck,
  startWith,
  tap,
} from 'rxjs/operators';
import { TremazeEventPrivilegeChecks } from '@tremaze/shared/feature/event/util/privilege-checks';
import { PermissionCheckService } from '@tremaze/shared/permission/services';
import { AbstractControl, Validators } from '@angular/forms';
import { TremazeValidators } from '@tremaze/shared/util/form';
import { AddressFormUtil } from '@tremaze/shared/feature/address/util/form';
import { Department } from '@tremaze/shared/feature/department/types';
import { CustomForm } from '@tremaze/shared/feature/custom-forms/types';
import { ControlsValue } from '@ngneat/reactive-forms/lib/types';
import { NotificationService } from '@tremaze/shared/notification';
import { User, UserType } from '@tremaze/shared/feature/user/types';
import {
  conditionalRepeatDaysValidator,
  TremazeSchedule,
  TremazeScheduleRepetitionType,
} from '@tremaze/shared/scheduling/types';
import { Category } from '@tremaze/shared/feature/category/types';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { Duration } from '@tremaze/duration';

export type EventTemplateFormModel = Omit<
  EventTemplate,
  'schedule' | 'users' | 'defaultAppointmentDuration'
> & {
  schedule: FormGroup<TremazeSchedule>;
  clients: User[];
  employees: User[];
  defaultAppointmentDuration: string;
  canBeReassigned: boolean;
};

@Injectable()
export class EventTemplateEditFormService {
  constructor(
    private readonly fb: FormBuilder,
    private readonly _assignableEditService: AssignableEditService,
    private readonly _permissionCheckService: PermissionCheckService,
    private readonly notificationService: NotificationService,
  ) {}

  formGroup: FormGroup<EventTemplateFormModel>;
  initialEventTemplate?: EventTemplate;

  init(initialData: EventTemplate, initialInstitution?: Institution) {
    this.initialEventTemplate = initialData;
    const initialValue = initialData ?? new EventTemplate();
    if (initialInstitution) {
      initialValue.institutions ??= [initialInstitution];
    }
    this.formGroup = this._buildFormGroup(initialValue);
    this._assignableEditService.init({
      hasGlobalLevelPrivileges$: this._hasGlobalWritePrivilege$,
      hasInstitutionLevelPrivileges$: this._hasInstitutionPermissions$,
      hasDepartmentLevelPrivileges$: this._hasDepartmentPermissions$,
      hasUserLevelPrivileges$: this._hasUserPermissions$,
      institutionValue$: this.formGroup.value$.pipe(pluck('institutions')),
      departmentValue$: this.formGroup.value$.pipe(pluck('departments')),
      userValue$: this.formGroup.value$.pipe(
        map((v) => [...v.clients, ...v.employees]),
      ),
      userTypesValue$: of([]),
    });
    this._initDynamicValidations();
  }

  destroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
    this._assignableEditService.destroy();
  }

  /**
   * PERMISSIONS
   */
  private _destroyed$ = new Subject<void>();

  private _hasGlobalWritePrivilege$ = from(
    this._permissionCheckService.checkPermission$(
      TremazeEventPrivilegeChecks.globalWritePrivilegeRequest(),
    ),
  );
  private _hasDepartmentPermissions$ = from(
    this._permissionCheckService.checkPermission$(
      TremazeEventPrivilegeChecks.createDepartmentCreateRequest(),
    ),
  );
  private _hasInstitutionPermissions$ = from(
    this._permissionCheckService.checkPermission$(
      TremazeEventPrivilegeChecks.createInstitutionCreateRequest(),
    ),
  );
  private _hasUserPermissions$ = from(
    this._permissionCheckService.checkPermission$(
      TremazeEventPrivilegeChecks.createUserCreateRequest(),
    ),
  );

  /**
   *  FORM MODEL & VALUE RETRIEVAL
   */

  private _buildFormGroup(initialValue: EventTemplate) {
    const defaultAppointmentDuration =
      initialValue.defaultAppointmentDuration?.asHHMM;

    const formGroup = this.fb.group<EventTemplateFormModel>({
      name: new FormControl<string>(initialValue.name, Validators.required),
      description: new FormControl(initialValue.description),
      address: AddressFormUtil.createFormGroup(initialValue.address, false),
      maxMember: new FormControl<number>(
        initialValue.maxMember ?? 0,
        Validators.min(0),
      ),
      institutions: this.fb.control<Institution[]>(
        initialValue?.institutions || [],
      ),
      institutionAssignments: this.fb.control<Institution[]>(
        initialValue?.institutionAssignments ?? [],
      ),
      departments: this.fb.control<Department[]>(
        initialValue?.departments ?? [],
      ),
      documentationForms: this.fb.control<CustomForm[]>(
        initialValue?.documentationForms ?? [],
      ),
      isPublic: this.fb.control<boolean>(initialValue.isPublic),
      userTypes: this.fb.control<UserType[]>(initialValue?.userTypes ?? []),
      visibleForFamily: this.fb.control<boolean>(initialValue.visibleForFamily),
      hasVideoMeeting: this.fb.control<boolean>(initialValue.hasVideoMeeting),
      hideWhenFull: this.fb.control<boolean>(initialValue.hideWhenFull),
      categories: this.fb.control<Category[]>(initialValue.categories),
      titleImage: this.fb.control<FileStorage>(initialValue.titleImage),
      registrationNecessary: this.fb.control<boolean>(
        initialValue.registrationNecessary,
      ),
      clients: [initialValue?.users?.filter((u) => !u.isEmployee) ?? []],
      employees: [initialValue?.users?.filter((u) => u.isEmployee) ?? []],
      defaultAppointmentDuration: this.fb.control<string>(
        defaultAppointmentDuration,
      ),
      specializations: this.fb.control(initialValue?.specializations ?? []),
      participationTypes: this.fb.control(
        initialValue?.participationTypes ?? [],
      ),
      enableParticipationInfo: this.fb.control<boolean>(
        initialValue.enableParticipationInfo,
      ),
      billable: this.fb.control<boolean>(initialValue.billable),
      visibleForAllReferenceClients: this.fb.control<boolean>(
        initialValue.visibleForAllReferenceClients,
      ),
      canBeReassignedTemplate: this.fb.control(
        initialValue.canBeReassignedTemplate,
      ),
      canBeReassigned: this.fb.control<boolean>(
        !!initialValue.canBeReassignedTemplate,
      ),
    } as any);

    if (initialValue.schedule) {
      formGroup.addControl(
        'schedule',
        this.buildScheduleFormGroup(this.fb, initialValue.schedule),
      );
    }

    return formGroup;
  }

  // Validates Form and return the value if valid. Else undefined.
  submitForm(): EventTemplate | undefined {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.valid) {
      const formValue: ControlsValue<EventTemplateFormModel> =
        this.formGroup.getRawValue();
      return EventTemplate.deserialize({
        ...formValue,
        id: this.initialEventTemplate?.id,
        defaultAppointmentDuration: formValue.defaultAppointmentDuration
          ? Duration.fromHHMM(formValue.defaultAppointmentDuration)
          : undefined,
      });
    }
  }

  /**
   *  DYNAMIC VALIDATIONS
   */

  async _initDynamicValidations() {
    const globalWritePrivilege = await firstValueFrom(
      this._hasGlobalWritePrivilege$,
    );
    if (!globalWritePrivilege) {
      this.formGroup.controls.institutionAssignments.setValidators([
        Validators.required,
        TremazeValidators.minLengthArray(1),
      ]);
    } else {
      this.formGroup.controls.institutionAssignments.clearValidators();
    }

    this.formGroup.controls.visibleForAllReferenceClients.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        startWith(this.formGroup.controls.visibleForAllReferenceClients.value),
        distinctUntilChanged(),
        tap((visibleForAllReferenceClients) => {
          const targets = [
            this.formGroup.controls.institutions,
            this.formGroup.controls.departments,
            this.formGroup.controls.clients,
          ];
          targets.forEach((t) => {
            if (visibleForAllReferenceClients) {
              this.formGroup.controls.isPublic.setValue(false);
              this.formGroup.controls.isPublic.disable();
              t.setValue([]);
              t.disable();
            } else {
              this.formGroup.controls.isPublic.enable();
              t.enable();
            }
          });
        }),
      )
      .subscribe();

    this.formGroup.controls.canBeReassigned.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        startWith(this.formGroup.controls.canBeReassigned.value),
        distinctUntilChanged(),
        tap((canBeReassigned) => {
          const control = this.formGroup.controls.canBeReassignedTemplate;
          if (!canBeReassigned) {
            control.setValue(null);
            control.disable();
            control.clearValidators();
          } else {
            control.enable();
            control.setValidators(Validators.required);
          }
        }),
      )
      .subscribe();

    this.formGroup.controls.hideWhenFull.valueChanges.pipe(
      startWith(this.formGroup.controls.hideWhenFull.value),
      tap(hideWhenFull => {
        if(hideWhenFull) {
          this.formGroup.controls.registrationNecessary.setValue(true);
          this.formGroup.controls.registrationNecessary.disable();
          const maxMemberControl = this.formGroup.controls.maxMember;
          if(maxMemberControl.value < 1) {
            maxMemberControl.setValue(1);
          }
        } else {
          this.formGroup.controls.registrationNecessary.enable();
        }
      })
    ).subscribe();

  }

  get isInstitutionAssignmentsRequired() {
    return this.formGroup.controls.institutionAssignments.hasValidator(
      Validators.required,
    );
  }

  /**
   *  ERRORS
   */

  get isInstitutionAssignmentsInvalid(): boolean {
    const control = this.formGroup.controls.institutionAssignments;
    return control.invalid;
  }

  get institutionAssignmentsErrorMsg(): string | null {
    const control = this.formGroup.controls.institutionAssignments;
    if (control.hasError('required') || control.hasError('minLengthArray')) {
      return 'Bitte wähle mindestens eine Einrichtung';
    }
    return null;
  }

  private _getMinErrorMessage(f: AbstractControl): null | string {
    const error = f.getError('min');
    if (error) {
      return `Dieser Wert darf nicht unter ${error.min} liegen`;
    }
  }

  get maxMemberErrorMessage(): null | string {
    const f = this.formGroup.getControl('maxMember');
    return f.hasError('min')
      ? this._getMinErrorMessage(f)
      : f.hasError('belowMin')
        ? 'Dieser Wert darf nicht unter Min. Teiln. liegen'
        : null;
  }

  /**
   *  DYNAMIC FORM FIELDS
   */

  public showInstitutionSelector$ =
    this._assignableEditService.showInstitutionSelector$;
  public showDepartmentSelector$ =
    this._assignableEditService.showDepartmentSelector$;
  public showUserSelector$ = this._assignableEditService.showUserSelector$;

  /**
   *  TRANSFER ADDRESS BUTTON
   */

  get showUseInstAddressButton() {
    return this.formGroup.value.institutions?.length;
  }

  get useAddressFromSelectedInstitutionLabelText(): string {
    const inst = this.formGroup.value.institutions;
    if (inst.length) {
      return `Adresse von ${inst[0].name} übernehmen`;
    }
  }

  get isUseAddressFromSelectedInstitutionButtonDisabled(): boolean {
    const inst = this.formGroup.value.institutions;
    if (inst.length) {
      return inst[0]?.address?.equals?.(this.formGroup.value.address);
    }
    return false;
  }

  onClickUseAddressFromSelectedInstitution(): void {
    const inst = this.formGroup.value.institutions;
    if (inst?.length) {
      this.formGroup.controls.address.patchValue(inst[0].address);
      this.notificationService.showNotification('Die Adresse wurde übernommen');
    }
  }

  /**
   *  SCHEDULE
   */

  addSchedule(repeatEvery: TremazeScheduleRepetitionType): void {
    this.formGroup.addControl('schedule', this.buildScheduleFormGroup(this.fb));
    this.formGroup.controls.schedule.patchValue({
      repeatEvery,
    });
    this.formGroup.controls.schedule.markAsDirty();
  }

  buildScheduleFormGroup(
    fb: FormBuilder,
    schedule?: Partial<TremazeEvent['schedule']>,
  ): FormGroup<TremazeSchedule> {
    return fb.group(
      {
        repeatEvery: [schedule?.repeatEvery ?? null, Validators.required],
        repeatNumber: [schedule?.repeatNumber ?? null, Validators.required],
        repeatDays: [schedule?.repeatDays ?? null],
        allowedRegistrationScope: [
          schedule?.allowedRegistrationScope ?? 'SINGLE',
          Validators.required,
        ],
      },
      {
        validators: [conditionalRepeatDaysValidator()],
      },
    );
  }

  removeSchedule(): void {
    this.formGroup.controls.schedule.reset();
    this.formGroup.removeControl('schedule');
  }
}
