import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import { Address, Meta } from '@tremaze/shared/models';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { Category } from '@tremaze/shared/feature/category/types';
import { Department } from '@tremaze/shared/feature/department/types';
import { EventBase } from './event-base';
import { CustomForm } from '@tremaze/shared/feature/custom-forms/types';
import { User, UserType } from '@tremaze/shared/feature/user/types';
import { TremazeSchedule } from '@tremaze/shared/scheduling/types';
import { Duration } from '@tremaze/duration';
import { Specialization } from '@tremaze/shared/feature/specialization/types';
import { EventParticipationType } from './event-participation-type';

@staticImplements<Deserializable<EventTemplate>>()
export class EventTemplate extends EventBase {
  constructor(
    readonly id?: string,
    readonly meta?: Meta,
    public name?: string,
    public address: Address = new Address(),
    public description?: string,
    public registrationNecessary?: boolean,
    public maxMember = 0,
    public institutions: Institution[] = [],
    public titleImage?: FileStorage,
    public categories: Category[] = [],
    public departments: Department[] = [],
    public documentationForms: CustomForm[] = [],
    public institutionAssignments: Institution[] = [],
    public isPublic?: boolean,
    public userTypes: UserType[] = [],
    public visibleForFamily?: boolean,
    public hasVideoMeeting?: boolean,
    public hideWhenFull?: boolean,
    public schedule?: TremazeSchedule,
    public users?: User[],
    public defaultAppointmentDuration?: Duration,
    public specializations: Specialization[] = [],
    public participationTypes: EventParticipationType[] = [],
    public enableParticipationInfo = false,
    public billable = false,
    public visibleForAllReferenceClients = false,
    public canBeReassignedTemplate?: EventTemplate,
  ) {
    super();
  }

  get instIds(): string[] {
    return this.institutions?.map((i) => i.id) ?? [];
  }

  get assignedInstIds(): string[] {
    return this.institutionAssignments?.map((i) => i.id) ?? [];
  }

  get departmentInstIds(): string[] {
    const result = new Set<string>(
      this.departments.map((d) => d.institution?.id),
    );
    return [...result];
  }

  static deserialize(data: any): null | EventTemplate {
    const usersFromForm = data?.clients?.concat(data?.employees) ?? [];

    const deserializeDuration = (duration: any) => {
      if (!duration) {
        return null;
      }
      return duration instanceof Duration
        ? duration
        : Duration.fromMinutes(duration);
    };

    return !data
      ? null
      : new EventTemplate(
          data.id,
          Meta.deserialize(data),
          data.name,
          Address.deserialize(data.address),
          data.description,
          data.registrationNecessary,
          data.maxMember,
          data.institutions?.map(Institution.deserialize) ?? [],
          FileStorage.deserialize(data.titleImage),
          data.categories?.map(Category.deserialize) ?? [],
          data.departments?.map(Department.deserialize) ?? [],
          data.documentationForms?.map(CustomForm.deserialize) ?? [],
          data.institutionAssignments?.map(Institution.deserialize) ?? [],
          data.isPublic ?? data.public ?? false,
          data.userTypes ?? [],
          data.visibleForFamily ?? false,
          data.hasVideoMeeting ?? false,
          data.hideWhenFull ?? false,
          TremazeSchedule.deserialize(data.schedule),
          (usersFromForm.length > 0
            ? usersFromForm
            : data.users?.map(User.deserialize)) ?? [],
          deserializeDuration(data.defaultAppointmentDuration),
          data.specializations?.map(Specialization.deserialize) ?? [],
          data.participationTypes ?? [],
          data.enableParticipationInfo ?? false,
          data.billable ?? false,
          data.visibleForAllReferenceClients ?? false,
          data.canBeReassignedTemplate
            ? EventTemplate.deserialize(data.canBeReassignedTemplate)
            : null,
        );
  }
}
