<div>
  @if (service.currentDirectory$ | async; as currentFolder) {
    <tremaze-folder-view-folders-and-files
      [folder]='currentFolder'
      [folders]='(service.currentSubDirectories$ | async) ?? []'
      [files]='(service.currentFiles$ | async) ?? []'
      [selectedFiles]='(service.selectedFiles$ | async) ?? []'
      [selectedFolders]='(service.selectedDirectories$ | async) ?? []'
      [showCreateFolder]='isCreatingFolder'
      [selectionType]='selectionType'
      [selectionRegex]='service.selectionRegex$ | async'
      [getCanWriteStreamForFileOrFolder]='
        service.canWriteFileOrFolderFn$ | async
      '
      [getCanDeleteStreamForFileOrFolder]='
         service.canDeleteFileOrFolderFn$ | async
      '
      [searchValue]='service.searchValue$ | async'
      [isLoadingFiles]='(service.isLoadingFiles$ | async) ?? true'
      [isLoadingFolders]='(service.isLoadingDirectories$ | async) ?? true'
      (navigate)='onNavigate($event)'
      (clickedFolder)='onClickDirectory($event)'
      (doubleClickedFolder)='onDoubleClickDirectory($event)'
      (clickedFile)='onClickFile($event)'
      (doubleClickedFile)='onDoubleClickFile($event)'
      (submitFolderName)='submitFolderName($event)'
      (submitFileName)='submitFileName($event)'
      (cancelCreateFolder)='cancelCreateFolder()'
      (showFilePreview)='service.openFile($event)'
      (duplicateFile)='service.copyFileToCurrentFolder($event)'
      (deleteFile)='service.deleteFile($event)'
      (copyFileLink)='service.copyFileLink($event)'
      (downloadFile)='service.downloadFile($event)'
      (openFolder)='service.openDirectory($event)'
      (deleteFolder)='service.deleteDirectory($event)'
      (selectionStart)='service.clearSelection()'
      (selectionChange)='service.setSelection($event.files, $event.folders)'
      (uploadFiles)='service.uploadFiles($event.files, $event.path)'
      (moveFilesAndFolders)='onMoveFilesAndFolders($event)'
      (searchValueChange)='onSearchValueChange($event)'
      (findClientFilesSettingChange)='onFindClientFilesSettingChange($event)'
    ></tremaze-folder-view-folders-and-files>
  }


</div>
@if (service.currentDirectory$ | async) {
  <tremaze-folder-view-actions-row
    [selectedFiles]='(service.selectedFiles$ | async) ?? []'
    [selectedFolders]='(service.selectedDirectories$ | async) ?? []'
    [hideSelectedFilesAndFoldersButton]='singleSelection'
    [showSelectFromFileStorageButton]='showSelectFromFileStorageButton'
    (addFolder)='createFolder()'
    (addFile)='fileInput.click()'
    (selectFromFileStorage)='clickedSelectFromFileStorage.emit()'
  >
    <ng-container slot='start'>
      <ng-content select='[slot=actions-start]'></ng-content>
    </ng-container>

    @if (selectionEnabled) {

      @if (canSelectFolders) {
        <button
          style='margin-right: 10px'
          mat-raised-button
          color='accent'
          (click)='onClickSelectCurrentFolder()'
          class='mat-elevation-z2'
        >
          Aktuellen Ordner wählen
        </button>
      }

      <button
        style='margin-right: 10px'
        mat-raised-button
        color='accent'
        [disabled]='service.hasNoSelection$ | async'
        (click)='onClickConfirmSelection()'
        class='mat-elevation-z2'
      >
        Auswahl bestätigen
      </button>
    }
  </tremaze-folder-view-actions-row>

  <input
    type='file'
    hidden
    #fileInput
    multiple
    (change)='onFilesSelected($event)'
  />
}
