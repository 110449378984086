import { Inject, Injectable, Optional } from '@angular/core';
import {
  DataSourceMethodsDeleteOptions,
  DataSourceMethodsGetFreshOptions,
  DataSourceMethodsPaginatedOptions,
  DefaultDataSourceMethods,
  DefaultREADDataSourceWithPaginationImpl,
  SortedFilteredPaginatedListParams,
} from '@tremaze/shared/util-http';
import {
  EventParticipationType,
  EventStatus,
  TremazeEvent,
} from '@tremaze/shared/feature/event/types';
import { HttpClient, HttpParams } from '@angular/common/http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import {
  EVENT_MODULE_CONFIG,
  EventModuleConfig,
} from '@tremaze/shared/feature/event/module-config';
import { InstitutionContextService } from '@tremaze/shared/feature/institution/shared/singletons';
import { connectable, Observable, ReplaySubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  EventDataSource,
  EventDateRange,
  EventPublishScopeOptions,
  EventScopeOptions,
  EventTypes,
} from './shared-feature-event-data-access';
import { removeUndefined } from '@tremaze/shared/util-utilities';
import { joinScopeOptions } from './helpers';
import { TremazeDate } from '@tremaze/shared/util-date';

@Injectable({ providedIn: 'root' })
export class RemoteEventCRUDDataSourceDefaultImpl
  extends DefaultREADDataSourceWithPaginationImpl<TremazeEvent>
  implements EventDataSource
{
  protected controller = 'events';

  protected deserializer = TremazeEvent.deserialize;

  private _subject = new ReplaySubject<EventParticipationType[]>(1);

  private readonly _allParticipationTypes$ = connectable(
    this._fetchAllParticipationTypes(),
    { connector: () => this._subject },
  );

  private _connection?: Subscription;

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
    @Optional()
    @Inject(EVENT_MODULE_CONFIG)
    protected readonly moduleConfig?: EventModuleConfig,
    @Optional()
    protected readonly institutionContextService?: InstitutionContextService,
  ) {
    super();
    this._connection = this._allParticipationTypes$.connect();
  }

  getFreshById(
    id: string,
    options?: DataSourceMethodsGetFreshOptions,
  ): Observable<TremazeEvent> {
    options ??= {};
    return super.getFreshById(id, {
      ...options,
      q: { ...options.q, markAsRead: 'false' },
    });
  }

  getAllParticipationTypes(): Observable<EventParticipationType[]> {
    return this._allParticipationTypes$.pipe();
  }

  private _fetchAllParticipationTypes(): Observable<EventParticipationType[]> {
    return this.http.get<EventParticipationType[]>('/participationTypes');
  }

  getCancellationReasonForEvent(
    eventId: string,
  ): Observable<string | null | undefined> {
    return this.http
      .get<{ cancellationReason?: string }>(`/events/${eventId}`, {
        params: { markAsRead: 'false' },
      })
      .pipe(map((r: any) => r?.cancellationReason));
  }

  updateCancellationReasonForEvent(
    eventId: string,
    cancellationReason?: string,
  ): Observable<void> {
    return this.http.put<void>(
      `${this.controller}/${eventId}/cancellationReason`,
      null,
      {
        params: { cancellationReason: cancellationReason ?? '' },
      },
    );
  }

  deleteById(
    id: string,
    options?: DataSourceMethodsDeleteOptions & EventScopeOptions,
    notifyUsers = true,
  ): Observable<boolean> {
    options = joinScopeOptions(options);
    return DefaultDataSourceMethods.deleteById(this.http, this.controller, id, {
      ...options,
      q: { ...options.q, notifyUsers: `${notifyUsers === true}` },
    });
  }

  getEventsInDateRange(
    dateRange: EventDateRange,
    types?: EventTypes[],
    onlyHolidayEvents?: boolean,
    options?: DataSourceMethodsPaginatedOptions,
    instIds: string[] = [],
    departmentIds: string[] = [],
    userIds: string[] = [],
    maxResults?: number,
    status?: EventStatus[],
    url = 'events',
  ): Observable<TremazeEvent[]> {
    return this.http
      .get<any[]>(url, {
        params: removeUndefined({
          ...(options?.q ?? {}),
          // need to add 2 hours since API just works with dates (which is wrong) this fix only works in CET though
          // TODO: fix this in the API and remove this workaround
          startDate: dateRange[0]?.clone().add(2, 'hour').toISOString(),
          endDate: dateRange[1]?.toISOString() ?? '',
          instIds: instIds.join(','),
          departmentIds: departmentIds.join(','),
          userIds: userIds.join(','),
          eventStatus: status?.join(','),
          ...SortedFilteredPaginatedListParams.toHttpParams(
            options?.filter ?? {},
          ),
        }),
      })
      .pipe(map((r) => r.map(this.deserializer)));
  }

  getEventsInDateRangeForUser(
    dateRange: EventDateRange,
    userId: string,
    types?: EventTypes[],
    onlyHolidayEvents?: boolean,
    options?: DataSourceMethodsPaginatedOptions,
    userIds: string[] = [],
    maxResults?: number,
    status?: EventStatus[],
  ): Observable<TremazeEvent[]> {
    return this.getEventsInDateRange(
      dateRange,
      types,
      onlyHolidayEvents,
      options,
      [],
      [],
      [userId, ...userIds],
      maxResults,
      status,
      `users/${userId}/events`,
    );
  }

  switchEventPublished(
    event: Pick<TremazeEvent, 'id' | 'published'>,
    options?: EventPublishScopeOptions,
    notifyUsers = true,
  ) {
    return this.setPublishedForEvent(
      event,
      !event.published,
      options,
      notifyUsers,
    );
  }

  updateEventStatus(id: string, status: EventStatus): Observable<void> {
    return this.http.put<void>(`${this.controller}/${id}/status`, null, {
      params: removeUndefined({ eventStatus: status }),
    });
  }

  protected setPublishedForEvent(
    event: Pick<TremazeEvent, 'id'>,
    published: boolean,
    options?: EventScopeOptions,
    notifyUsers = true,
  ) {
    const params = new HttpParams({
      fromObject: { notifyUsers: `${notifyUsers}`, eventScope: options?.scope },
    });
    return this.http.put<boolean>(
      `${this.controller}/${event.id}/${!published ? 'un' : ''}publish`,
      null,
      {
        params,
      },
    );
  }

  moveEvent(
    eventId: string,
    startDate: TremazeDate,
    endDate: TremazeDate,
    allDay: boolean,
    moveUser?: {
      previousUserId: string;
      newUserId: string;
    },
  ): Observable<void> {
    return this.http.put<void>(`events/${eventId}/move`, {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      allDay,
      moveUser,
    });
  }
}
