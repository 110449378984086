import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { FolderViewBreadcrumbsComponent } from '../breadcrumbs/folder-view-breadcrumbs.component';
import { FolderViewGridLayoutFolderItemComponent } from '../grid-layout/folder-item/folder-view-grid-layout-folder-item.component';
import { FolderViewGridLayoutFoldersComponent } from '../grid-layout/folders/folder-view-grid-layout-folders.component';
import { FolderViewGridLayoutFilesComponent } from '../grid-layout/files/folder-view-grid-layout-files.component';
import { FolderViewGridLayoutFileItemComponent } from '../grid-layout/file-item/folder-view-grid-layout-file-item.component';
import { FolderViewDragPreviewComponent } from './drag-preview/folder-view-drag-preview.component';
import animations from '../animations';
import { SelectZoneDirective, SelectZoneModule } from '@tremaze/select-zone';
import {
  DragZoneDirective,
  DragZoneModule,
  DragZonePreviewComponent,
} from '@tremaze/drag-zone';
import { firstValueFrom, Observable, take, tap } from 'rxjs';
import { FileDropInDropZoneDirective } from '@tremaze/file-drop-in';
import { IconComponent } from '@tremaze/shared/ui/icon';
import { ExpandingIconTextInputComponent } from '@tremaze/shared/ui/inputs/expanding-icon-text-input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  MatButtonToggle,
  MatButtonToggleGroup,
} from '@angular/material/button-toggle';
import { FolderViewListLayoutListComponent } from '../list-layout/list/folder-view-list-layout-list.component';
import { FolderViewSelectionType } from '../../types';
import { FolderViewPersonalSettingsService } from '../../services/folder-view-personal-settings.service';
import { MatProgressBar } from '@angular/material/progress-bar';
import { ConfirmationService } from '@tremaze/shared/feature/confirmation';
import { MatIconButton } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckbox } from '@angular/material/checkbox';
import { SharedPermissionUiDirectivesModule } from '@tremaze/shared/permission/ui/directives';
import { TzPermissionRequest } from '@tremaze/shared/permission/types';

type ViewMode = 'grid' | 'list';

@Component({
  selector: '‚tremaze-folder-view-folders-and-files',
  standalone: true,
  imports: [
    CommonModule,
    FolderViewBreadcrumbsComponent,
    FolderViewGridLayoutFolderItemComponent,
    FolderViewGridLayoutFoldersComponent,
    FolderViewGridLayoutFilesComponent,
    FolderViewGridLayoutFileItemComponent,
    FolderViewDragPreviewComponent,
    SelectZoneModule,
    DragZoneModule,
    FileDropInDropZoneDirective,
    IconComponent,
    ExpandingIconTextInputComponent,
    MatProgressSpinner,
    MatButtonToggleGroup,
    MatButtonToggle,
    FolderViewListLayoutListComponent,
    MatProgressBar,
    MatIconButton,
    MatMenuModule,
    MatCheckbox,
    SharedPermissionUiDirectivesModule,
  ],
  templateUrl: './folder-view-folders-and-files.component.html',
  styleUrl: './folder-view-folders-and-files.component.scss',
  animations: [animations.listAnimation],
  hostDirectives: [SelectZoneDirective, DragZoneDirective],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'folder-view-folders-and-files',
  },
})
export class FolderViewFoldersAndFilesComponent
  implements OnInit, AfterViewInit
{
  private _selectZone = inject(SelectZoneDirective, { self: true });
  private _dragZone = inject(DragZoneDirective, { self: true });
  private readonly _personalSettingsService = inject(
    FolderViewPersonalSettingsService,
  );
  private readonly _confirmationService = inject(ConfirmationService);

  readonly clientFileStorageSearchPermissionRequest: TzPermissionRequest = {
    iPerms: {
      instIds: 'ANY',
      perms: 'CLIENT_FILE_STORAGE_SEARCH',
    },
  };

  @ViewChild(DragZonePreviewComponent)
  dragPreviewComponent?: DragZonePreviewComponent;

  @Input({ required: true }) folder?: DirStorage;
  @Input({ required: true }) folders?: DirStorage[];
  @Input({ required: true }) files?: FileStorage[];

  @Input() isLoadingFiles = false;
  @Input() isLoadingFolders = false;

  get isLoading() {
    return this.isLoadingFiles || this.isLoadingFolders;
  }

  @Input() selectedFolders?: DirStorage[];
  @Input() selectedFiles?: FileStorage[];

  @Input() showCreateFolder = false;

  @Input() selectionRegex?: RegExp | null;
  private _selectionType?: FolderViewSelectionType;

  get selectionType() {
    return this._selectionType;
  }

  @Input() set selectionType(value: FolderViewSelectionType | undefined) {
    this._selectionType = value;
    if (this.singleSelection) {
      this._selectZone.disabled = true;
    }
  }

  get singleSelection() {
    return (
      this.selectionType === 'singleFile' ||
      this.selectionType === 'singleFolder'
    );
  }

  @Input() set disableSelectZone(value: boolean) {
    this._selectZone.disabled =
      value || this.viewMode === 'list' || this.singleSelection;
  }

  @Input() getCanWriteStreamForFileOrFolder?: (
    file: FileStorage | DirStorage,
  ) => Observable<boolean>;
  @Input() getCanDeleteStreamForFileOrFolder?: (
    file: FileStorage | DirStorage,
  ) => Observable<boolean>;

  @Input() searchValue: string | null = null;

  private _viewMode: ViewMode = 'grid';

  get viewMode() {
    return this._viewMode;
  }

  @Input() set viewMode(value: ViewMode) {
    this._viewMode = value;
    this._selectZone.disabled = value === 'list';
    this._personalSettingsService.updateViewMode(value);
  }

  @Output() readonly navigate = new EventEmitter<string>();
  @Output() readonly clickedFile = new EventEmitter<FileStorage>();
  @Output() readonly clickedFolder = new EventEmitter<DirStorage>();
  @Output() readonly doubleClickedFolder = new EventEmitter<DirStorage>();
  @Output() readonly doubleClickedFile = new EventEmitter<FileStorage>();
  @Output() readonly submitFolderName = new EventEmitter<{
    name: string;
    folderId?: string;
  }>();
  @Output() readonly submitFileName = new EventEmitter<{
    name: string;
    fileId: string;
  }>();
  @Output() readonly cancelCreateFolder = new EventEmitter<void>();
  @Output() readonly showFilePreview = new EventEmitter<FileStorage>();
  @Output() readonly duplicateFile = new EventEmitter<FileStorage>();
  @Output() readonly deleteFile = new EventEmitter<FileStorage>();
  @Output() readonly downloadFile = new EventEmitter<FileStorage>();
  @Output() readonly copyFileLink = new EventEmitter<FileStorage>();
  @Output() readonly openFolder = new EventEmitter<DirStorage>();
  @Output() readonly deleteFolder = new EventEmitter<DirStorage>();
  @Output() readonly uploadFiles = new EventEmitter<{
    files: File[];
    path?: string;
  }>();
  @Output() readonly selectionStart = new EventEmitter<void>();
  @Output() readonly selectionChange = new EventEmitter<{
    files: FileStorage[];
    folders: DirStorage[];
  }>();
  @Output() readonly moveFilesAndFolders = new EventEmitter<{
    files: FileStorage[];
    folders: DirStorage[];
    targetPath: string;
  }>();
  @Output() readonly searchValueChange = new EventEmitter<string>();
  @Output() readonly findClientFilesSettingChange = new EventEmitter<boolean>();

  ngOnInit() {
    if (this.viewMode === 'list') {
      this._selectZone.disabled = true;
    }
    this._personalSettingsService.settings$
      .pipe(
        take(1),
        tap((s) => {
          if (s?.viewMode) {
            this.viewMode = s.viewMode;
          }
        }),
      )
      .subscribe();

    this._selectZone.selectionStart.subscribe(() => {
      this.selectionStart.emit();
    });
    this._selectZone.selectionChange.subscribe((i) => {
      const items = i as (FileStorage | DirStorage)[];
      const files: FileStorage[] = [];
      const folders: DirStorage[] = [];
      items.forEach((item) => {
        if (item instanceof FileStorage) {
          files.push(item);
        } else {
          folders.push(item);
        }
      });
      this.selectionChange.emit({ files, folders });
    });
  }

  ngAfterViewInit() {
    this._dragZone.dragPreviewTemplate = this.dragPreviewComponent;
  }

  @HostListener('click')
  onClick() {
    if (!this._dragZone.isDragging && !this._selectZone.disabled) {
      this.selectionStart.emit();
    }
  }

  isFileDisabled(file: FileStorage): boolean {
    if (this.selectionRegex) {
      return !this.selectionRegex.test(file.fileType);
    }
    return false;
  }

  isFolderSelected(folder: DirStorage): boolean {
    return this.selectedFolders?.some((f) => f.id === folder.id) ?? false;
  }

  isFileSelected(file: FileStorage) {
    return this.selectedFiles?.some((f) => f.id === file.id) ?? false;
  }

  onClickFolder(folder: DirStorage, event: Event) {
    event.stopPropagation();
    this.clickedFolder.emit(folder);
  }

  onClickBreadcrumb(path: string) {
    this.navigate.emit(path);
  }

  onClickFile(file: FileStorage, event: Event) {
    event.stopPropagation();
    this.clickedFile.emit(file);
  }

  onDoubleClickFolder(folder: DirStorage) {
    this.doubleClickedFolder.emit(folder);
  }

  onDoubleClickFile(file: FileStorage) {
    this.doubleClickedFile.emit(file);
  }

  onSubmitFolderName(name: string, folderId?: string) {
    this.submitFolderName.emit({ name, folderId });
  }

  onCancelCreateFolder() {
    this.cancelCreateFolder.emit();
  }

  async onDataDropped(data: unknown[], path: string) {
    const confirmed = await firstValueFrom(
      this._confirmationService.askUserForConfirmation(),
    );
    if (!confirmed?.confirmed) {
      return;
    }
    const d = data as (FileStorage | DirStorage)[];
    const files: FileStorage[] = [];
    const folders: DirStorage[] = [];
    d.forEach((item) => {
      if (item instanceof FileStorage) {
        files.push(item);
      } else {
        folders.push(item);
      }
    });
    this.moveFilesAndFolders.emit({ files, folders, targetPath: path });
  }

  onFilesDropped(files: File[], path?: string) {
    this.uploadFiles.emit({ files, path });
  }

  onClickMenuItem(event: Event) {
    event.stopPropagation();
  }
}
