import { Injectable, Optional } from '@angular/core';
import { SortedFilteredTableDataManipulationService } from '@tremaze/shared/sorted-filtered-paginated-table/services';
import {
  EventScope,
  EventStatus,
  EventTemplate,
  isCanceledEventStatus,
  TremazeEvent,
} from '@tremaze/shared/feature/event/types';
import { SortedFilteredTableDataManipulationServiceResponse } from '@tremaze/shared/sorted-filtered-paginated-table/types';
import { filter, Observable, of, take, zip } from 'rxjs';
import { Router } from '@angular/router';
import {
  EventTemplateResultType,
  EventTemplateSelectService,
} from '@tremaze/shared/feature/event/template/feature/select';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ConfirmationService } from '@tremaze/shared/feature/confirmation';
import { NotificationService } from '@tremaze/shared/notification';
import { EventStateService } from '@tremaze/shared/feature/event';
import { EventDataSource } from '@tremaze/shared/feature/event/data-access';
import { EventScopeSelectorService } from '@tremaze/shared/feature/event/feature/event-scope-selector';
import { TremazeDate } from '@tremaze/shared/util-date';
import { TremazeEventPrivilegeChecks } from '@tremaze/shared/feature/event/util/privilege-checks';
import { ReferencePersonService } from '@tremaze/shared/feature/reference-person';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';
import { PermissionCheckService } from '@tremaze/shared/permission/services';
import {
  EventPreset,
  EventPresetSelectionService,
} from '@tremaze/shared/feature/event/feature/event-preset-selection';
import { TenantConfigService } from '@tremaze/shared/tenant-config';
import { SelectUserNotificationService } from '@tremaze/select-user-notification';
import {
  catchErrorMapTo,
  filterNotNullOrUndefined,
  filterTrue,
} from '@tremaze/shared/util/rxjs';
import { CustomForm } from '@tremaze/shared/feature/custom-forms/types';
import { HttpClient } from '@angular/common/http';
import { EventCancellationSettingServiceService } from '@tremaze/event-cancellation-reason';

export interface EventEditParams {
  eventId?: string;
  isEdit: boolean;
  templateId?: string;
  userIds?: string[];
  institutionIds?: string[];
  departmentIds?: string[];
  date?: TremazeDate;
  dateTime?: TremazeDate;
  allDay?: boolean;
  preset?: EventPreset;
  dontAssignAuthUser?: boolean;
}

export abstract class EventEditService extends SortedFilteredTableDataManipulationService<TremazeEvent> {
  abstract override createItem(
    config?: Omit<EventEditParams, 'isEdit'>,
  ): Observable<
    | null
    | undefined
    | SortedFilteredTableDataManipulationServiceResponse<TremazeEvent>
  >;

  abstract updateDates(
    event: TremazeEvent,
    newStartDate: TremazeDate,
    newEndDate: TremazeDate,
    allDay: boolean,
    userUpdate?: { oldUserId: string; newUserId: string },
    updateNotifierId?: string,
  ): Observable<void>;

  abstract replanEvent(event: TremazeEvent): void;

  abstract override deleteItem(
    ev: TremazeEvent,
  ): Observable<boolean | undefined | null>;

  abstract switchPublished(
    ev: TremazeEvent,
  ): Observable<boolean | null | undefined>;

  abstract override editItem(
    item: TremazeEvent,
  ): Observable<SortedFilteredTableDataManipulationServiceResponse<TremazeEvent> | null>;

  abstract joinVideoMeeting(item: TremazeEvent): void;

  abstract notifyEventReload(updateId?: string): void;

  abstract editEventCancellationReason(eventId: string): Observable<void>;

  abstract updateEventStatus(
    id: string,
    status: EventStatus,
    remainingBudget?: number,
    config?: { skipReload: boolean },
  ): Observable<boolean>;
}

@Injectable()
export class EventEditServiceDefaultImpl implements EventEditService {
  constructor(
    private readonly _router: Router,
    private readonly _confirmationService: ConfirmationService,
    private readonly _notificationService: NotificationService,
    private readonly _dataSource: EventDataSource,
    private readonly _eventScopeSelector: EventScopeSelectorService,
    private readonly _authService: AuthV2Service,
    private readonly _referencePersonService: ReferencePersonService,
    private readonly _permissionCheckService: PermissionCheckService,
    private readonly _eventPresetSelectionService: EventPresetSelectionService,
    private readonly _selectUserNotificationService: SelectUserNotificationService,
    private readonly _http: HttpClient,
    private readonly _eventCancellationSettingServiceService: EventCancellationSettingServiceService,
    @Optional() private readonly _tenantConfigService?: TenantConfigService,
    @Optional() private eventStateService?: EventStateService,
    @Optional() private templateSelectService?: EventTemplateSelectService,
  ) {}

  private getEvent(id: string) {
    return zip(
      this._http.get(`events/${id}`),
      this._http
        .get(`events/${id}/forms/documentation`)
        .pipe(catchErrorMapTo([])),
    ).pipe(
      map(([event, documentation]) => {
        const documentationForms = (
          (documentation as Array<unknown>) ?? []
        ).map(CustomForm.deserialize);
        const data = {
          ...event,
          documentationForms,
        };
        return TremazeEvent.deserialize(data);
      }),
    );
  }

  private _updateDates(
    id: string,
    startDate: TremazeDate,
    endDate: TremazeDate,
    allDay: boolean,
    userUpdate?: { oldUserId: string; newUserId: string },
  ): Observable<void> {
    return this.getEvent(id).pipe(
      filterNotNullOrUndefined(),
      switchMap((event) => {
        event.startDate = startDate;
        event.endDate = endDate;
        event.isAllDay = allDay;

        if (userUpdate) {
          const eventHasPreviousUser = event.userIds.includes(
            userUpdate.oldUserId,
          );
          if (eventHasPreviousUser) {
            return this._dataSource.moveEvent(id, startDate, endDate, allDay, {
              previousUserId: userUpdate.oldUserId,
              newUserId: userUpdate.newUserId,
            });
          }
        }

        return this._dataSource.moveEvent(id, startDate, endDate, allDay);
      }),
    );
  }

  private _openEditComponent(cfg: EventEditParams) {
    const config = { ...cfg } as Partial<EventEditParams>;
    let queryParams: Record<string, unknown> = {};
    if (config.dateTime) {
      queryParams['startDateTime'] = config.dateTime.toISOString();
      delete config['dateTime'];
      delete config['date'];
    } else if (config.date) {
      queryParams['startDate'] = config.date.format('YYYY-MM-DD');
      delete config['date'];
    }
    const isEdit = config.isEdit;
    const eventId = config.eventId;
    if (isEdit) {
      delete config['eventId'];
    }
    delete config['isEdit'];

    queryParams = {
      ...queryParams,
      ...config,
    };

    // make all array values to string
    for (const [key, value] of Object.entries(queryParams)) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          queryParams[key] = value.join(',');
        } else {
          delete queryParams[key];
        }
      }
    }

    this._router.navigate(['event', isEdit ? eventId : 'create'], {
      queryParams,
    });
  }

  createItem(
    config?: Omit<EventEditParams, 'isEdit'>,
  ): Observable<
    | SortedFilteredTableDataManipulationServiceResponse<TremazeEvent>
    | null
    | undefined
  > {
    const editConfig: EventEditParams = {
      ...config,
      isEdit: false,
    };

    const skipPreset = !!config!.userIds?.length;

    const templateSelect$: Observable<EventTemplateResultType> =
      !config!.eventId && this.templateSelectService?.select
        ? this.templateSelectService?.select()
        : of('CONTINUE_WITHOUT');

    templateSelect$
      .pipe(
        filter((r) => r !== 'CANCEL'),
        map((template) => {
          if (template === 'CONTINUE_WITHOUT') {
            return null;
          }
          return (template as EventTemplate).id;
        }),
        switchMap((templateOrNull) => {
          return (
            this._tenantConfigService?.eventPresetsEnabled$ ?? of(true)
          ).pipe(
            switchMap((enabled) => {
              if (!enabled || skipPreset) {
                const noPreset: EventPreset = 'NONE';
                return of(noPreset);
              }
              return this._eventPresetSelectionService.selectPreset();
            }),
            map((preset) => ({ templateOrNull, preset })),
          );
        }),
        tap(({ templateOrNull, preset }) => {
          if (!preset) {
            return;
          }
          if (templateOrNull) {
            return this._openEditComponent({
              ...editConfig,
              templateId: templateOrNull ?? undefined,
              preset,
            });
          }
          return this._openEditComponent({ ...editConfig, preset });
        }),
      )
      .subscribe();

    return of(null);
  }

  updateDates(
    event: TremazeEvent,
    newStartDate: TremazeDate,
    newEndDate: TremazeDate,
    allDay: boolean,
    userUpdate?: { oldUserId: string; newUserId: string },
    updateNotifierId?: string,
  ): Observable<void> {
    if (event.isMultiDay) {
      this._notificationService
        .showNotification({
          message:
            'Mehrtägige Termine können nur über die Bearbeiten-Seite verschoben werden',
          actionName: 'Zur Bearbeiten-Seite',
        })
        .pipe(
          tap((r) => {
            if (r === 'ACTION') {
              this.editItem(event);
            }
          }),
        )
        .subscribe();
      return of();
    }

    if (!event.id) {
      this._notificationService.showDefaultErrorNotification();
      return of();
    }

    return this.getEvent(event.id).pipe(
      filterNotNullOrUndefined(),
      switchMap((event) =>
        TremazeEventPrivilegeChecks.getEditPrivilegeRequest$(
          event,
          this._authService,
          this._referencePersonService,
        ).pipe(
          take(1),
          switchMap((request) => {
            return this._permissionCheckService.checkPermission$(request);
          }),
          switchMap((hasPermission) => {
            if (hasPermission) {
              return this.performAction<void>(
                event,
                () => {
                  return this._updateDates(
                    event.id!,
                    newStartDate,
                    newEndDate,
                    allDay,
                    userUpdate,
                  ).pipe(
                    this._notificationService.notifyOnError(),
                    tap(() => {
                      this.notifyEventReload(updateNotifierId);
                    }),
                  );
                },
                ['SINGLE'],
                true,
              ).pipe(map(() => void 0));
            }
            this._notificationService.showNotification(
              'Du hast keine Berechtigung',
            );
            return of();
          }),
        ),
      ),
    );
  }

  replanEvent(event: TremazeEvent) {
    this._openEditComponent({
      isEdit: false,
      eventId: event.id,
    });
  }

  deleteItem(ev: TremazeEvent): Observable<boolean | null> {
    return (
      ev.hasDocumentation === true
        ? this._confirmationService
            .askUserForConfirmation({
              title: 'Dokumentieten Termin löschen',
              text: 'Der Termin verfügt über eine Dokumentation. Soll der Termin trotzdem gelöscht werden?',
              warn: true,
              confirmButtonText: 'Ja, löschen',
            })
            .pipe(map((r) => r?.confirmed === true))
        : of(true)
    ).pipe(
      filterTrue(),
      switchMap(() =>
        this._selectUserNotificationService.askUserForNotification(),
      ),
      filterNotNullOrUndefined(),
      switchMap((notifyUsers) =>
        this.performAction<boolean>(ev, (scope) => {
          return this._dataSource
            .deleteById(ev.id!, { scope }, notifyUsers)
            .pipe(
              tap((r) => {
                if (r) {
                  this._notificationService.showNotification(
                    `Die ${
                      scope === 'ALL' ? 'Serie' : 'Termin(e)'
                    } wurde gelöscht`,
                  );
                  this.notifyEventReload();
                } else {
                  this._notificationService.showDefaultErrorNotification();
                }
              }),
            );
        }),
      ),
    );
  }

  switchPublished(ev: TremazeEvent) {
    const notify$ =
      ev.published === true
        ? this._selectUserNotificationService.askUserForNotification()
        : of(true);
    return notify$.pipe(
      filterNotNullOrUndefined(),
      switchMap((notifyUsers) =>
        this.performAction<boolean>(
          ev,
          (scope) => {
            return this._dataSource
              .switchEventPublished(ev, { scope }, notifyUsers)
              .pipe(
                tap((r) => {
                  if (r) {
                    this._notificationService.showNotification(
                      ev.published
                        ? 'Die Veröffentlichung wurde zurückgezogen'
                        : 'Der Termin wurde veröffentlicht',
                    );
                    this.notifyEventReload();
                  } else {
                    this._notificationService.showDefaultErrorNotification();
                  }
                }),
              );
          },
          ['SINGLE', 'ALL'],
        ),
      ),
    );
  }

  editItem(
    item: TremazeEvent,
  ): Observable<SortedFilteredTableDataManipulationServiceResponse<TremazeEvent> | null> {
    this._router.navigate(['/event', item.id], {
      queryParams: { date: item.startDate!.format('YYYY-MM-DD') },
    });
    return of(null);
  }

  joinVideoMeeting(item: TremazeEvent) {
    this._router.navigate(['/event', item.id, `video-meeting`], {
      queryParams: {
        date: item.startDate!.format('YYYY-MM-DD'),
        subject: item.name,
      },
    });
  }

  notifyEventReload(updateId?: string) {
    if (this.eventStateService instanceof EventStateService) {
      this.eventStateService.triggerEventsReload(updateId);
    }
  }

  editEventCancellationReason(eventId: string) {
    return this._eventCancellationSettingServiceService.open(eventId).pipe(
      filter((r) => r?.changed),
      map(() => void 0),
    );
  }

  updateEventStatus(
    id: string,
    status: EventStatus,
    remainingBudget?: number,
    config?: { skipReload: boolean },
  ): Observable<boolean> {
    const req$ = this._dataSource.updateEventStatus(id, status).pipe(
      map((r) => true),
      tap(() => {
        if (!config?.skipReload) {
          this.notifyEventReload();
        }
      }),
    );

    if (isCanceledEventStatus(status)) {
      return this._eventCancellationSettingServiceService
        .open(id)
        .pipe(switchMap(() => req$));
    }

    if ((remainingBudget ?? 1) <= 0 && status === 'COMPLETED') {
      return this._confirmationService
        .askUserForConfirmation({
          title: 'Budget wird überschritten',
          text: 'Das Budget wird durch die Änderung des Status überschritten. Möchtest du fortfahren?',
          warn: true,
        })
        .pipe(
          switchMap((c) => {
            if (!c?.confirmed) {
              return of(false);
            }
            return req$;
          }),
        );
    }

    return req$;
  }

  private performAction<T>(
    ev: TremazeEvent,
    callback: (scope: EventScope) => Observable<T>,
    allowedScopes?: EventScope[],
    skipScopeSelectionIfOnlyOne?: boolean,
  ): Observable<T | null> {
    const scope$ =
      skipScopeSelectionIfOnlyOne && allowedScopes?.length === 1
        ? of(allowedScopes[0])
        : this._eventScopeSelector.selectEventScope(ev, allowedScopes);
    return scope$.pipe(
      switchMap((scope) => {
        if (!scope) {
          throw new Error();
        }
        return this._confirmationService
          .askUserForConfirmation({ warn: true })
          .pipe(
            switchMap((conti) => {
              if (conti?.confirmed) {
                return callback(scope) as never;
              }
              throw null;
            }),
          );
      }),
      catchError(() => of(null)),
    );
  }
}

export const provideEventEditService = () => ({
  provide: EventEditService,
  useClass: EventEditServiceDefaultImpl,
});
