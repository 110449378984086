<ng-container [formGroup]="formGroup">
  <section class="form-grid">
    <mat-form-field>
      <mat-label>Name des Termins</mat-label>
      <input matInput required formControlName="name" maxlength="60" />
      <mat-error *ngIf="formGroup.controls.name.invalid"
        >Bitte gib einen Namen an
      </mat-error>
      <mat-hint>{{ formGroup.get('name').value?.length ?? 0 }} / 60</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Kategorien</mat-label>
      <tremaze-category-selector
        multiple
        formControlName="categories"
      ></tremaze-category-selector>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Max. Teilnehmeranzahl</mat-label>
      <input matInput type="number" [min]="maxMemberMinValue()" formControlName="maxMember" />
      <mat-error *ngIf="formGroup.controls.maxMember.invalid"
        >{{ eventTemplateForm.maxMemberErrorMessage }}
      </mat-error>
    </mat-form-field>

    <ng-content></ng-content>

    <mat-form-field class="form-grid-item-new-row">
      <mat-label>Titelbild</mat-label>
      <tremaze-file-selector-input
        [aspectRatio]="16 / 9"
        entityName="EVENT"
        formControlName="titleImage"
        [instId]="instIdsOfAssignedEntities$ | async"
      ></tremaze-file-selector-input>
    </mat-form-field>
  </section>
</ng-container>
