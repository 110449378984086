import { TremazeEvent } from '@tremaze/shared/feature/event/types';
import { TremazeSchedule } from '@tremaze/shared/scheduling/types';
import { TremazeDate } from '@tremaze/shared/util-date';
import { CategoryFixtures } from '../category';
import { JitsiMeetingRoom } from '@tremaze/shared/feature/jitsi-meet/types';
import { PageGenerator } from '../helpers';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { UserFixtures } from '../user';

class EventFixturePageGenerator extends PageGenerator<TremazeEvent> {
  override generateByIndex(index: number): TremazeEvent {
    return EventFixtures.generateEvent(index);
  }
}

export abstract class EventFixtures {
  static readonly generateEvent = (id: number) => {
    return new TremazeEvent(
      id.toString(),
      undefined,
      'Event ' + id,
      undefined,
      `Description ${id}`,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      [CategoryFixtures.eventCategory1],
      undefined,
      undefined,
      undefined,
      undefined,
      new TremazeDate('2025-01-01T00:00:00.000Z'),
      new TremazeDate('2025-01-01T12:00:00.000Z'),
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      new TremazeSchedule(
        undefined,
        undefined,
        new TremazeDate('2025-01-01T00:00:00.000Z'),
        new TremazeDate('2025-12-31T00:00:00.000Z'),
        'WEEK',
        ['MONDAY', 'WEDNESDAY', 'FRIDAY'],
        2,
        undefined,
      ),
      undefined,
      undefined,
      undefined,
      undefined,
      UserFixtures.generateUser(1),
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      new JitsiMeetingRoom('1', [], [], 'Meeting Room', '', 'CODE'),
      undefined,
      undefined,
      undefined,
    );
  };

  private static _eventStubPageGenerator = new EventFixturePageGenerator();

  static readonly generateEventPage = (
    options: DataSourceMethodsPaginatedOptions,
  ) => EventFixtures._eventStubPageGenerator.generatePage(options);

  static readonly event1 = EventFixtures.generateEvent(1);
  static readonly event2 = EventFixtures.generateEvent(2);
  static readonly event3 = EventFixtures.generateEvent(3);
  static readonly event4 = EventFixtures.generateEvent(4);
  static readonly event5 = EventFixtures.generateEvent(5);
}
