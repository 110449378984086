import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tenant } from '@tremaze/shared/feature/tenant/types';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';

@Injectable({ providedIn: 'root' })
export class ActiveTenantService {
  private readonly _authService = inject(AuthV2Service);

  getTenant$(): Observable<Tenant> {
    return this._authService.activeTenant$;
  }
}
