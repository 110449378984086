import { Component, inject } from '@angular/core';
import { EventTemplateEditFormService } from '../../event-template-edit-form.service';
import { map } from 'rxjs';
import { EventTemplate } from '@tremaze/shared/feature/event/types';
import { DefaultRemoteEventTemplateDataSource } from '@tremaze/shared/feature/event/template/data-access';

@Component({
  selector: 'tremaze-event-template-registration-settings',
  templateUrl: './event-template-registration-settings.component.html',
})
export class EventTemplateRegistrationSettingsComponent {
  readonly eventTemplateFormService = inject(EventTemplateEditFormService);
  private readonly _templateDateSource = inject(
    DefaultRemoteEventTemplateDataSource,
  );

  get formGroup() {
    return this.eventTemplateFormService?.formGroup;
  }

  readonly eventTemplateAutocompleteDataSource = (filterValue: string) =>
    this._templateDateSource
      .getPaginated({
        filter: {
          filterValue,
          filterFields: ['NAME'],
          sort: 'name',
          sortDirection: 'asc',
        },
      })
      .pipe(
        map((r) =>
          r.content.filter(
            (t) =>
              t.id !== this.eventTemplateFormService.initialEventTemplate?.id,
          ),
        ),
      );

  readonly displayTemplateFn = (template: EventTemplate) => template?.name;

  get showTemplateAutocomplete(): boolean {
    return this.formGroup.controls.canBeReassigned.value;
  }

  readonly featureAssistantMessage =
    'Wenn der Termin über mehr als eine Kategorie verfügt, können die Nutzer*innen bei der Anmeldung eine dieser Kategorien als "Termingrund" auswählen.';
}
