import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventTemplateDataTableComponent } from './data-table.component';
import { SharedSortedFilteredPaginatedTableModule } from '@tremaze/shared/sorted-filtered-paginated-table';
import { SharedFeatureEventTemplateFeatureEditModule } from '@tremaze/shared/feature/event/template/feature/edit';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedPermissionUiDirectivesModule } from '@tremaze/shared/permission/ui/directives';
import {
  DataTablePersonalConfigSettingsKeyDirective,
  SharedUiDataTableModule,
} from '@tremaze/shared/ui/data-table';
import { SharedUiDataTableUiChipsColModule } from '@tremaze/shared/ui/data-table/ui/chips-col';
import { SpecializationsSelectComponent } from '@tremaze/specializations-select';
import { IfModuleEnabledDirective } from '@tremaze/shared/tenant-config';
import { MatMenuItem } from '@angular/material/menu';
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  imports: [
    CommonModule,
    SharedSortedFilteredPaginatedTableModule,
    SharedFeatureEventTemplateFeatureEditModule,
    MatButtonModule,
    MatTooltipModule,
    SharedPermissionUiDirectivesModule,
    SharedUiDataTableModule,
    DataTablePersonalConfigSettingsKeyDirective,
    SharedUiDataTableUiChipsColModule,
    SpecializationsSelectComponent,
    IfModuleEnabledDirective,
    MatMenuItem,
    MatIconModule
  ],
  declarations: [EventTemplateDataTableComponent],
  exports: [EventTemplateDataTableComponent],
})
export class SharedFeatureEventTemplateFeatureDataTableModule {}
