import { inject, Injectable } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from '@tremaze/shared/notification';
import { MonoTypeOperatorFunction } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CopyToClipboardService {
  private readonly _clipboard = inject(Clipboard);
  private readonly _notificationService = inject(NotificationService);

  copyToClipboard(text: string): void {
    this._clipboard.copy(text);
    this._notificationService.showNotification('In die Zwischenablage kopiert');
  }

  // rxjs operator to copy a string to the clipboard
  copyToClipboard$: MonoTypeOperatorFunction<string> = (source) =>
    source.pipe(tap((text) => this.copyToClipboard(text)));
}
