import { Component, computed, OnInit } from '@angular/core';
import { FormGroup } from '@ngneat/reactive-forms';
import {
  EventTemplate,
  TremazeEvent,
} from '@tremaze/shared/feature/event/types';
import { EventTemplateEditFormService } from '../../event-template-edit-form.service';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';
import { ControlsValue } from '@ngneat/reactive-forms/lib/types';
import { EventFormUtil } from '@tremaze/shared/feature/event/util/form';
import { toSignal } from '@angular/core/rxjs-interop';
import { mapTo, Observable, of, pipe } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';

@Component({
  selector: 'tremaze-event-template-form-general-section',
  templateUrl: './general.component.html',
})
export class EventTemplateFormGeneralSectionComponent<T extends EventTemplate>
  implements OnInit
{
  constructor(
    public readonly eventTemplateForm: EventTemplateEditFormService,
    private readonly authService: AuthV2Service,
  ) {}

  get formGroup(): FormGroup<T> {
    return this.eventTemplateForm?.formGroup as any;
  }

  /**
   *  INSTITUTION IDS OF ASSIGNED ENTITIES FOR IMAGE SELECTOR
   */

  instIdsOfAssignedEntities$: Observable<string[]>;

  get authUserInstIds$(): Observable<string[]> {
    return this.authService.authenticatedUser$.pipe(map((r) => r.instIds));
  }

  private readonly _isHideWhenFullSet$ =
    this.formGroup.controls.hideWhenFull.valueChanges.pipe(
      // for some god-damn reason, typescript is telling me that pipe() must not
      // have any arguments passed in. So we have to do this
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      startWith(this.formGroup.controls.hideWhenFull.value),
    );

  readonly isHideWhenFullSet = toSignal(this._isHideWhenFullSet$);

  readonly maxMemberMinValue = computed(() => {
    const isHideWhenFullSet = this.isHideWhenFullSet();
    if (isHideWhenFullSet) {
      return 1;
    }
    return 0;
  });

  ngOnInit() {
    this.instIdsOfAssignedEntities$ = this.formGroup.value$.pipe(
      distinctUntilAssignmentsChanged(),
      mapToInstitutionIdsOfAssignedEntities(this.formGroup),
      switchMap((ids) => {
        if (!ids?.length) {
          return this.authUserInstIds$;
        }
        return of(ids);
      }),
      distinctUntilChanged(),
    );
  }
}

const distinctUntilAssignmentsChanged = () =>
  distinctUntilChanged<ControlsValue<EventTemplate>>(
    (a, b) =>
      a.institutionAssignments?.length === b.institutionAssignments?.length &&
      a.departments?.length === b.departments?.length &&
      a['users']?.length === b['users']?.length,
  );

const mapToInstitutionIdsOfAssignedEntities = <T extends EventTemplate>(
  formGroup: FormGroup<T>,
) =>
  pipe(
    mapTo(formGroup),
    map((d) =>
      EventFormUtil.extractTremazeEventFormGroupValue(d as any, false),
    ),
    map((event: TremazeEvent) => event.instIdsWithDepartmentsAndUsers),
  );
