import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from "rxjs";
import { DirStorage } from '@tremaze/shared/feature/file-storage/types';

@Injectable()
export class FolderViewEventsService implements OnDestroy {
  readonly reloadCurrentDirectory$ = new Subject<void>();
  readonly refreshCurrentSubDirectories$ = new Subject<void>();
  readonly refreshCurrentFiles$ = new Subject<void>();
  readonly directoryCreated$ = new Subject<DirStorage>();
  readonly searchValueChange$ = new Subject<string>();
  readonly findClientFiles$ = new BehaviorSubject<boolean>(false);

  ngOnDestroy(): void {
    this.reloadCurrentDirectory$.complete();
    this.refreshCurrentSubDirectories$.complete();
    this.refreshCurrentFiles$.complete();
    this.directoryCreated$.complete();
    this.searchValueChange$.complete();
    this.findClientFiles$.complete();
  }
}
