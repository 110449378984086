import {
  Directive,
  effect,
  inject,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { TenantWebsiteLinksService } from './tenant-website-links.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[tremazeIfCanUseTenantLinks]',
  standalone: true,
})
export class IfCanUseTenantLinksDirective {
  private readonly _viewContainerRef = inject(ViewContainerRef);
  private readonly _templateRef = inject(TemplateRef);
  private readonly _tenantWebsiteLinksService = inject(
    TenantWebsiteLinksService,
  );

  private readonly _enabled = toSignal(
    this._tenantWebsiteLinksService.tenantWebsiteLinksEnabled$,
  );

  private readonly _effect = effect(() => {
    const enabled = this._enabled();
    if (enabled) {
      this._viewContainerRef.createEmbeddedView(this._templateRef);
    } else {
      this._viewContainerRef.clear();
    }
  });
}
