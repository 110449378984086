import { Address, Meta } from '@tremaze/shared/models';
import { ParticipationCriteria } from '@tremaze/shared/feature/participation-criteria/types';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { Category } from '@tremaze/shared/feature/category/types';
import { Department } from '@tremaze/shared/feature/department/types';
import { Currency, Price } from './price';
import { CustomForm } from '@tremaze/shared/feature/custom-forms/types';
import { EventParticipationType } from './event-participation-type';

export abstract class EventBase {
  protected constructor(
    readonly id?: string,
    readonly meta?: Meta,
    public name?: string,
    public address: Address = new Address(),
    public description?: string,
    public eventCriteria: ParticipationCriteria[] = [],
    public registrationNecessary?: boolean,
    public minAge = 0,
    public maxAge = 0,
    public maxMember = 0,
    public prices: Price[] = [new Price(0, Currency.EURO)],
    public highlight?: boolean,
    public workshop?: boolean,
    public institutions: Institution[] = [],
    public titleImage?: FileStorage,
    public confirmationByParent?: boolean,
    public parentConfirmationAge = 15,
    public categories: Category[] = [],
    public departments: Department[] = [],
    public documentationForms: CustomForm[] = [],
    public participationTypes: EventParticipationType[] = [],
    public enableParticipationInfo = false,
    public billable = false,
    public visibleForAllReferenceClients = false,
  ) {}

  get price(): number {
    return Price.findEuroPriceRepresented(this.prices);
  }

  set price(value) {
    Price.findEuroPrice(this.prices).value = value * 100;
  }

  get mandatoryCriteria() {
    return this.eventCriteria?.filter((c) => c.criteria);
  }

  get nonMandatoryCriteria() {
    return this.eventCriteria?.filter((c) => !c.criteria);
  }

  get category() {
    if (this.categories.length === 1) {
      return this.categories[0];
    }
    return null;
  }

  get instIds(): string[] {
    return this.institutions?.map((i) => i.id) ?? [];
  }

  get departmentInstIds(): string[] {
    const result = new Set<string>(
      this.departments.map((d) => d.institution?.id),
    );
    return [...result];
  }
}
